import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'aup-form-field-error',
  standalone: true,
  imports: [NgClass],
  templateUrl: './form-field-error.component.html',
  styleUrl: './form-field-error.component.scss',
})
export class FormFieldErrorComponent {
  @Input() controls!: Array<AbstractControl>;
  @Input() errors: { [key: string]: string } = {
    required: 'This field is required.',
    invalid: 'This field is invalid.',
  };
  @Input() removeInnerSpacings: boolean = false;

  get errorMessages(): string[] {
    let messages: string[] = [];
    this.controls.forEach((controlOrArray) => {
      if (Array.isArray(controlOrArray)) {
        controlOrArray.forEach((control) => {
          messages = messages.concat(this.getErrorMessagesForControl(control));
        });
      } else {
        messages = messages.concat(
          this.getErrorMessagesForControl(controlOrArray),
        );
      }
    });
    return messages;
  }

  private getErrorMessagesForControl(control: AbstractControl): string[] {
    if (!control.errors) {
      return [];
    }
    return Object.keys(control.errors)
      .filter((err) => this.errors[err])
      .map((err) => this.errors[err]);
  }
}
