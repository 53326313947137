import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';

// components
import { lastValueFrom } from 'rxjs';

import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AnnouncmentsService } from '../../services/announcments.service';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../services/modal.service';
import { WaterMeterService } from '../../services/water-meter.service';

@Component({
  selector: 'aup-main',
  standalone: true,
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  imports: [RouterOutlet, HeaderComponent, SidenavComponent],
})
export class MainComponent implements OnInit {
  constructor(
    private _authService: AuthService,
    private _wmService: WaterMeterService,
    private _modalService: ModalService,
    private _ancService: AnnouncmentsService,
    private _vcr: ViewContainerRef,
  ) {}
  async ngOnInit(): Promise<void> {
    // !!! Important for Modals working, do not delete !!!
    this._modalService.setViewContainerRef(this._vcr);
    await lastValueFrom(this._authService.getUser());
    await lastValueFrom(this._wmService.getWaterMeters());
  }
}
