import { Routes } from '@angular/router';

import { EmailConfirmationComponent } from './components/auth/email-confirmation/email-confirmation.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { MainComponent } from './components/main/main.component';
import { mainRoutes } from './components/main/main.routes';
import { NotFoundComponent } from './components/utilities/not-found/not-found.component';
import { authGuard } from './guards/auth.guard';
import { noAuthGuard } from './guards/no-auth.guard';

// components

// routes

export const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [noAuthGuard] },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'confirm-email',
    component: EmailConfirmationComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'confirm-email/:token',
    component: EmailConfirmationComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: '',
    component: MainComponent,
    children: mainRoutes,
    canMatch: [authGuard],
  },
  { path: '**', component: NotFoundComponent },
];
