@if (loading) {
  <aup-loader [isDark]="true"></aup-loader>
}
<div class="mb-2 text-2xl font-bold title">{{ 'add_water_meter.title' | translate }}</div>

<div class="flex items-center base-bg lg:max-w-[578px]">
  <div class="flex items-center justify-center">
    <aup-icon class="h-14 w-14 logo fill-brand-primary" name="meter" ></aup-icon>
  </div>
  <div class="mb-9 w-full md:text-center">
    <p class="my-5 text-center text-lg font-bold text-black">{{ 'add_water_meter.guide_1' | translate}}</p>
    <p class="mb-3">{{ 'add_water_meter.guide_3' | translate}} <span class="font-bold">{{'add_water_meter.guide_3_bold' | translate}}</span></p>
<!--    <p class="italic">If you want to connect several contracts, you need to repeat the above procedure for each individual contract.</p>-->
  </div>
  <div class="flex w-full items-center justify-center">
    <form [formGroup]="newWaterMeterForm" (ngSubmit)="onSubmit()" class="flex w-full flex-col items-center justify-center text-base">
      <div class="flex w-full flex-col items-center justify-center">
        <label class="font-bold text-font-secondary mb-2.5" for="contractNumber">{{ 'add_water_meter.billing_number_label' | translate}}</label>
        <div class="relative flex w-full items-center">
          <input type="text" class="form-input-bright form-input-bright--with-icon" id="contractNumber" formControlName="contractNumber" autocomplete="off" [placeholder]="'add_water_meter.billing_number_placeholder' | translate">
          <div class="group">
            <aup-icon class="absolute right-1 bottom-1 flex h-8 w-8 items-center justify-center text-font-secondary fill-font-secondary group-hover:fill-brand-primary" data-popover-target="contract-number-help" data-popover-placement="right" [name]="'question-mark'"></aup-icon>
            <div data-popover id="contract-number-help" role="tooltip" class="invisible absolute z-10 inline-block w-72 border border-gray-200 text-sm text-white opacity-0 shadow-sm transition-opacity duration-300 bg-brand-primary rounded-[15px] max-w-[200px]">
              <div class="p-5">
                <p class="mb-2 text-center">
                  {{'add_water_meter.billing_number_help' | translate}}
                </p>
                <p class="text-center">
                  {{ 'add_water_meter.billing_number_example' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <aup-form-field-error [removeInnerSpacings]="true" [controls]="[newWaterMeterForm.controls['contractNumber']]" [errors]="{'required': 'add_water_meter.billing_number_error_required' | translate}"></aup-form-field-error>
      </div>
      <div class="flex w-full flex-col items-center justify-center">
        <label class="text-center font-bold text-font-secondary mb-2.5" for="siteNumber">{{'add_water_meter.site_id_label' | translate}}</label>
        <div class="relative flex h-full w-full items-center justify-center">
          <input type="text" class="form-input-bright form-input-bright--with-icon" id="siteNumber" formControlName="siteNumber" autocomplete="off" [placeholder]="'add_water_meter.site_id_placeholder' | translate">
          <div class="group">
            <aup-icon class="absolute right-1 bottom-1 flex h-8 w-8 items-center justify-center text-font-secondary fill-font-secondary group-hover:fill-brand-primary" data-popover-target="site-number-help" data-popover-placement="right" [name]="'question-mark'"></aup-icon>
            <div data-popover id="site-number-help" role="tooltip" class="invisible absolute z-10 inline-block w-72 border border-gray-200 text-sm text-white opacity-0 shadow-sm transition-opacity duration-300 bg-brand-primary rounded-[15px] max-w-[200px]">
              <div class="p-5">
                <p class="mb-2 text-center">
                  {{'add_water_meter.site_id_help' | translate}}
                </p>
                <p class="text-center">
                  {{ 'add_water_meter.site_id_example' | translate}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <aup-form-field-error [removeInnerSpacings]="true" [controls]="[newWaterMeterForm.controls['siteNumber']]" [errors]="{'required': 'add_water_meter.site_id_error_required' | translate}"></aup-form-field-error>

      </div>
      <button class="mb-4 primary-button" [disabled]="(!newWaterMeterForm.valid && newWaterMeterForm.touched) || loading" type="submit">{{'add_water_meter.submit' | translate}}</button>
    </form>
  </div>
</div>
