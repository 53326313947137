import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs';

import { environment } from '../../environments/environment';
import {
  IAuthApiResponse,
  IInitPasswordResetForm,
  ILoginForm,
  IPasswordResetForm,
  IRegisterForm,
} from '../models/IAuth.types';
import { IUser, IUserUpdateForm } from '../models/IUser.types';
import { ApiResponse, ApiResponseBase } from '../models/IUtil.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userSubject: BehaviorSubject<IUser | null>;
  public user$: Observable<IUser | null>;
  private _translate: TranslateService;
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _toastr: ToastrService,
    private injector: Injector,
  ) {
    this._translate = this.injector.get(TranslateService); // Manually retrieve the TranslateService

    this._userSubject = new BehaviorSubject<IUser | null>(null);
    this.user$ = this._userSubject.asObservable();
  }

  public get userValue(): IUser | null {
    return this._userSubject.value;
  }

  setUser(user: IUser): void {
    this._userSubject.next(user);
  }

  login(user: ILoginForm): Observable<IAuthApiResponse | void> {
    const { email, password } = user;
    if (!email || !password)
      return throwError(() => new Error('Invalid email or password'));
    return this._http
      .post<IAuthApiResponse>(`${environment.apiUrl}/users/login`, {
        email: user.email,
        password: user.password,
      })
      .pipe(
        map((response) => {
          localStorage.setItem('accessToken', response['token']);
          const user = response['user'];
          if (user) this._userSubject.next(user);
          this._router.navigate(['/']);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  logout(): void {
    if (localStorage.getItem('accessToken')) {
      this._http.post(`${environment.apiUrl}/users/logout`, {}).subscribe({
        next: (_) => {},
        error: (e) => {
          this.showError(e.error.message);
        },
      });
      localStorage.removeItem('accessToken');
      this._userSubject.next(null);
      this._router.navigate(['/login']);
    }
  }

  register(user: IRegisterForm): Observable<ApiResponseBase> {
    return this._http
      .post<ApiResponseBase>(`${environment.apiUrl}/users/register`, {
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        password: user.password,
        phone_number: user.phone,
        policy_agreement: user.termsAndConditions,
        gdpr_agreement: user.promoAgreement,
      })
      .pipe(
        tap((_) => {
          this._router.navigate(['/confirm-email']);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  initPasswordReset(
    initPasswordResetForm: IInitPasswordResetForm,
  ): Observable<ApiResponseBase> {
    return this._http
      .post<ApiResponseBase>(`${environment.apiUrl}/users/forgot-password`, {
        email: initPasswordResetForm.email,
      })
      .pipe(
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  resetPassword(
    passwordResetForm: IPasswordResetForm,
  ): Observable<IAuthApiResponse> {
    return this._http
      .post<IAuthApiResponse>(`${environment.apiUrl}/users/reset-password`, {
        token: passwordResetForm.token,
        password: passwordResetForm.password,
      })
      .pipe(
        tap((response) => {
          localStorage.setItem(
            'accessToken',
            response['tokens'] ?? response['token'],
          );
          const user = response['user'];
          if (user) this._userSubject.next(user);
          this._router.navigate(['/']);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  verifyEmail(token: string): Observable<IAuthApiResponse> {
    return this._http
      .get<IAuthApiResponse>(
        `${environment.apiUrl}/users/verify-email/${token}`,
      )
      .pipe(
        tap((response) => {
          localStorage.setItem(
            'accessToken',
            response['tokens'] ?? response['token'],
          );
          const user = response['user'];
          if (user) this._userSubject.next(user);
          this._router.navigate(['/']);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  refreshToken(): Observable<IAuthApiResponse> {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throwError(() => new Error('Token Not Found'));
    }
    return this._http
      .post<IAuthApiResponse>(`${environment.apiUrl}/users/refresh`, { token })
      .pipe(
        tap((response) => {
          localStorage.setItem('accessToken', response['token']);
          const user = response['user'];
          if (user) this._userSubject.next(user);
          this._router.navigate(['/']);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  public checkTokenExpiration(): boolean {
    const token = localStorage.getItem('accessToken');
    if (!token) return true;
    const decoded: JwtPayload = jwtDecode(token);
    if (!decoded.exp) return true;
    return decoded.exp > Date.now();
  }

  public getUser() {
    return this._http
      .get<IAuthApiResponse>(`${environment.apiUrl}/users/me`)
      .pipe(
        tap((response) => {
          const user = response['user'];
          if (user) this._userSubject.next(user);
        }),
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
      );
  }

  isLoggedIn(): boolean {
    // Added for testing purposes
    const token = localStorage.getItem('accessToken');
    if (!token) return false;
    return !!token;
  }

  updateUserData(
    form: Partial<IUserUpdateForm>,
  ): Observable<ApiResponse<string>> {
    return this._http
      .put<ApiResponse<string>>(`${environment.apiUrl}/users/edit`, form)
      .pipe(
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
        tap(async (response) => {
          if (response.status) {
            this._toastr.success('User data updated', 'Success');
            await lastValueFrom(this.getUser());
          }
        }),
      );
  }

  private showError(errorMessage: string | null): void {
    this._toastr.error(
      errorMessage || this._translate.instant('notifications.error.generic'),
      this._translate.instant('notifications.error.title'),
    );
  }

  updateDefaultLanguage(language: string) {
    return this._http
      .patch<
        ApiResponse<string>
      >(`${environment.apiUrl}/settings/language`, { language })
      .pipe(
        catchError((e) => {
          this.showError(e.error.message);
          return throwError(() => new Error(e));
        }),
        tap(async (response) => {
          console.log(response);
          if (response.status) {
            this._toastr.success(
              this._translate.instant('notifications.success.generic'),
              this._translate.instant('notifications.success.title'),
            );
            await lastValueFrom(this.getUser());
          }
        }),
      );
  }
}
