import { AsyncPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { AnnouncmentsService } from '../../../services/announcments.service';
import { LoaderComponent } from '../../utilities/loader/loader.component';

// components

@Component({
  selector: 'aup-announcements',
  standalone: true,
  templateUrl: './announcements.component.html',
  styleUrl: './announcements.component.scss',
  imports: [TranslateModule, AsyncPipe, DatePipe, LoaderComponent],
})
export class AnnouncementsComponent {
  constructor(protected ancService: AnnouncmentsService) {}
}
