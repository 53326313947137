@if (ancService.isLoading) {
  <aup-loader [isDark]="true"></aup-loader>
} @else {
  <div id="accordion-collapse" data-accordion="collapse" data-active-classes="opened" data-inactive-classes="closed">
    <div class="grid grid-cols-5 gap-4">
      <div class="col-span-5 2xl:col-span-3">
        <div class="mb-2 text-2xl font-bold title">{{ 'faq.faq' | translate }}</div>
          @if (faqs.length > 0) {
            @for (faq of faqs; track faq.id; let idx = $index; let last = $last) {
                <div (keydown.enter)="onClick()" (click)="onClick()" [tabindex]="idx"  class="my-4 py-3 base-bg rounded-plate">
                  <h2 [id]="'accordion-collapse-heading-' + (idx + 1)">
                    <button type="button" class="flex w-full items-center justify-between text-lg font-bold text-black" [attr.data-accordion-target]="'#accordion-collapse-body-' + (idx + 1)" aria-expanded="false" [attr.aria-controls]="'accordion-collapse-body-' + (idx + 1)">
                      <span class="truncate pr-2">{{faq.title}}</span>
                      <svg data-accordion-icon class="h-3 w-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1,1l4,4l4-4" />
                      </svg>
                    </button>
                  </h2>
                  <div [id]="'accordion-collapse-body-' + (idx + 1)" class="hidden py-4" [attr.aria-labelledby]="'accordion-collapse-heading-' + (idx + 1)">
                    <p>{{faq.content}}</p>
                  </div>
                </div>

            }
          } @else {
            <div class="flex items-start justify-start">
              <span class="text-lg text-gray-500">{{ 'faq.no_faq' | translate }}</span>
            </div>
          }

      </div>
    </div>
  </div>
}
