import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from '../../../services/auth.service';
import { FormFieldErrorComponent } from '../../utilities/form-field-error/form-field-error.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';
import {
  passwordsMatchValidator,
  passwordStrengthValidator,
} from '../../utilities/validators/custom-form-validators';

@Component({
  selector: 'aup-register',
  standalone: true,
  imports: [
    LoaderComponent,
    FormsModule,
    ReactiveFormsModule,
    FormFieldErrorComponent,
    TranslateModule,
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  registerForm: FormGroup = new FormGroup(
    {
      firstName: new FormControl<string>('', [Validators.required]),
      lastName: new FormControl<string>('', [Validators.required]),
      phone: new FormControl<string>('', [Validators.required]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl<string>('', [
        Validators.required,
        passwordStrengthValidator(),
      ]),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        passwordsMatchValidator,
      ]),
      termsAndConditions: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      promoAgreement: new FormControl<boolean>(false),
    },
    { validators: passwordsMatchValidator },
  );
  loading: boolean = false;
  formSubmitted: boolean = false;

  constructor(private authService: AuthService) {}

  onSubmit(): void {
    if (this.registerForm.valid) {
      this.loading = true;
      this.authService.register(this.registerForm.value).subscribe({
        next: (_) => {
          this.loading = false;
        },
        error: (e) => {
          console.error('Registration error:', e);
          this.registerForm.controls['password'].setValue('');
          this.registerForm.controls['confirmPassword'].setValue('');
          this.loading = false;
        },
      });
    } else {
      this.registerForm.markAllAsTouched();
    }
  }
}
