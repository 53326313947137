import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, debounceTime, first, Subscription } from 'rxjs';

import { ModalService } from '../../../../../services/modal.service';
import { WaterMeterService } from '../../../../../services/water-meter.service';
import { IconComponent } from '../../../icon/icon.component';
import { ModalComponent } from '../../modal/modal.component';
import { BaseModalComponent } from '../../modal/modal.models';

@Component({
  selector: 'aup-enable-wm-monitoring-modal',
  standalone: true,
  imports: [
    ModalComponent,
    IconComponent,
    ReactiveFormsModule,
    AsyncPipe,
    NgClass,
    TranslateModule,
  ],
  templateUrl: './enable-wm-monitoring-modal.component.html',
  styleUrl: './enable-wm-monitoring-modal.component.scss',
})
export class EnableWmMonitoringModalComponent
  implements OnInit, OnDestroy, BaseModalComponent
{
  @Input() id!: string;
  enableMonitoringForm: FormGroup = new FormGroup({
    monitor: new FormControl<boolean>(false),
  });
  loading: boolean = false;
  currentColorClass: string = 'fill-brand-primary';
  initialMonitorState?: boolean;

  private _toggleSubject = new BehaviorSubject<boolean>(false);
  private _subscriptions: Subscription = new Subscription();

  constructor(
    protected modal: ModalService,
    private _wmService: WaterMeterService,
  ) {}

  ngOnInit() {
    const currentWm = this._wmService.currentWm$
      .pipe(first())
      .subscribe((wm) => {
        this.initialMonitorState = wm?.monitoring === 1;
        this.enableMonitoringForm.patchValue({
          monitor: this.initialMonitorState,
        });
        this.currentColorClass = this.initialMonitorState
          ? 'fill-brand-red'
          : 'fill-brand-primary';
        this._toggleSubject.next(this.initialMonitorState);
      });
    this._subscriptions.add(currentWm);
    this.setupToggleSubscription();
  }

  ngOnDestroy() {
    this._toggleSubject.unsubscribe();
    this._subscriptions.unsubscribe();
  }

  setContext(context: unknown): void {
    Object.assign(this, context);
  }

  onSubmit() {
    if (this.enableMonitoringForm.valid) {
      const resSub = this._wmService
        .enableMonitoring(this.enableMonitoringForm.value)
        .subscribe({
          next: (_) => {
            console.log('Monitoring status updated', _);
            this.modal.closeModal(this.id);
          },
          error: (e) => {
            console.error('Error updating monitoring status:', e);
          },
        });
      this._subscriptions.add(resSub);
    }
  }
  setupToggleSubscription() {
    this._toggleSubject.pipe(debounceTime(500)).subscribe((monitoring) => {
      if (this.initialMonitorState === monitoring) return;
      this.onSubmit();
    });
  }

  checkChange(e: Event) {
    const checked = (e.target as HTMLInputElement).checked;
    this.currentColorClass = checked ? 'fill-brand-red' : 'fill-brand-primary';
    this._toggleSubject.next(checked);
  }

  get toggleState() {
    return this._toggleSubject.asObservable();
  }
}
