import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

function passwordsMatchValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const formGroup = control as FormGroup; // Type assertion
  const password = formGroup.get('password')?.value;
  const confirmPassword = formGroup.get('confirmPassword')?.value;

  if (
    password &&
    confirmPassword &&
    password !== confirmPassword &&
    formGroup.touched
  ) {
    return { passwordsMismatch: true };
  }
  return null;
}

function passwordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value || '';
    let errorMessage: string = 'Password must contain at least: ';

    // Check for minimum length
    if (value.length < 8) {
      errorMessage += '8 characters, ';
    }

    // Check for uppercase letters
    if (!/[A-Z]/.test(value)) {
      errorMessage += 'one uppercase letter, ';
    }

    // Check for lowercase letters
    if (!/[a-z]/.test(value)) {
      errorMessage += 'one lowercase letter, ';
    }

    // Check for numbers
    if (!/[0-9]/.test(value)) {
      errorMessage += 'one number, ';
    }

    // Check for symbols
    if (!/[^A-Za-z0-9]/.test(value)) {
      errorMessage += 'one symbol, ';
    }

    // Trim the trailing comma and space
    if (errorMessage !== 'Password must contain at least: ') {
      errorMessage = errorMessage.slice(0, -2) + '.';
    } else {
      // No errors, return null
      return null;
    }

    return { passwordStrengthError: errorMessage };
  };
}

export { passwordsMatchValidator, passwordStrengthValidator };
