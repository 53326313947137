import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';

import { SliderSlideDirective } from './slide.directive';

@Component({
  selector: 'aup-slider',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
  template: `
    <div class="slider__container">
      <div class="slider__body">
        @if (arrows && (infinite || currentSlide > 0)) {
          <div class="slider__previous">
            <button (click)="previousSlide()">
              <svg viewBox="0 0 20 20">
                <polyline
                  points="12 2, 7 10, 12 18"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  fill="transparent"
                  stroke-linejoin="round"
                ></polyline>
              </svg>
            </button>
          </div>
        }
        <ol
          [style.left.%]="currentSlide * -100"
          class="slider__slides"
          (mouseenter)="stopInterval()"
          (mouseleave)="startInterval()"
        >
          @for (slide of slides; let index = $index; track slide) {
            <li
              [ngClass]="{ 'slider__slide--active': index === currentSlide }"
              class="slider__slide"
            >
              <ng-container [ngTemplateOutlet]="slide.template"></ng-container>
            </li>
          }
        </ol>
        @if (arrows && (infinite || currentSlide < slides.length - 1)) {
          <div class="slider__next">
            <button (click)="nextSlide()">
              <svg viewBox="0 0 20 20">
                <polyline
                  points="8 2, 13 10, 8 18"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  fill="transparent"
                  stroke-linejoin="round"
                ></polyline>
              </svg>
            </button>
          </div>
        }
        @if (dots && slides.length > 1) {
          <ng-container>
            <ol class="slider__dots">
              @for (slide of slides; let index = $index; track slide) {
                <li>
                  <button
                    (click)="goToSlide(index + 1, $event)"
                    [ngClass]="{
                      'slider__dot--active': index === currentSlide,
                    }"
                    class="slider__dot"
                  >
                    <span class="sr-only">{{ index + 1 }}</span>
                  </button>
                </li>
              }
            </ol>
          </ng-container>
        }
      </div>
    </div>
  `,
  styleUrl: './slider.component.scss',
})
export class SliderComponent
  implements OnChanges, AfterContentChecked, OnDestroy
{
  @Input() public dots: boolean = true;
  @Input() public infinite: boolean = true;
  @Input() public autoplay: boolean = true;
  @Input() public autoplaySpeed: number = 3000;
  @Input() public arrows: boolean = true;

  @Output() public page: EventEmitter<number> = new EventEmitter<number>();
  @Output() public previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() public next: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(SliderSlideDirective)
  public slides: QueryList<SliderSlideDirective> =
    new QueryList<SliderSlideDirective>();

  public currentSlide: number = 0;

  private interval: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngAfterContentChecked(): void {
    // console.log('slides', this.slides)
    this.cdr.detectChanges();
    this.currentSlide = Math.max(
      Math.min(this.currentSlide, this.slides.length - 1),
      0,
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['autoplay']) {
      if (changes['autoplay'].currentValue) {
        this.startInterval();
      } else {
        this.stopInterval();
      }
    }
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.stopInterval();
  }

  public previousSlide($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (this.currentSlide <= 0) {
      this.currentSlide = this.slides.length - 1;
    } else {
      this.currentSlide--;
    }

    this.emitPrevious();
    this.restartInterval();
  }

  public nextSlide($event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (this.currentSlide >= this.slides.length - 1) {
      this.currentSlide = 0;
    } else {
      this.currentSlide++;
    }

    this.emitNext();
    this.restartInterval();
  }

  public goToSlide(slide: number, $event?: MouseEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    const index = slide - 1;

    if (index < 0 || index >= this.slides.length) {
      return;
    }

    if (index === this.currentSlide) {
      return;
    }

    this.currentSlide = index;
    this.emitPage();
    this.restartInterval();
  }

  public startInterval(): void {
    if (!this.autoplay) {
      return;
    }
    this.interval = setInterval(
      () => {
        this.nextSlide();
      },
      Math.max(this.autoplaySpeed, 500),
    );
  }

  public stopInterval(): void {
    if (!this.autoplay) {
      return;
    }
    clearInterval(this.interval);
  }

  private emitPrevious(): void {
    this.previous.emit(this.currentSlide + 1);
    this.emitPage();
  }

  private emitNext(): void {
    this.next.emit(this.currentSlide + 1);
    this.emitPage();
  }

  private emitPage(): void {
    this.page.emit(this.currentSlide + 1);
  }

  private restartInterval(): void {
    this.stopInterval();
    this.startInterval();
  }
}
