import { HttpInterceptorFn } from '@angular/common/http';

export const localeInterceptor: HttpInterceptorFn = (req, next) => {
  const locale = navigator.language || 'en-US'; // Get the browser's locale, default to 'en-US'
  const modifiedReq = req.clone({
    setHeaders: {
      'Accept-Language': locale,
    },
  });
  return next(modifiedReq);
};
