import { AsyncPipe, DecimalPipe, NgClass } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Subscription } from 'rxjs';

import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import { WaterMeterService } from '../../../services/water-meter.service';
import { ChartComponent } from '../../utilities/chart/chart.component';
import { WmDropdownComponent } from '../../utilities/dropdowns/wm-dropdown/wm-dropdown.component';
import { IconComponent } from '../../utilities/icon/icon.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';

@Component({
  selector: 'aup-problem-reporting',
  standalone: true,
  templateUrl: './problem-reporting.component.html',
  styleUrl: './problem-reporting.component.scss',
  imports: [
    ChartComponent,
    DecimalPipe,
    FlowbiteMarkDirective,
    IconComponent,
    RouterLink,
    AsyncPipe,
    NgClass,
    ReactiveFormsModule,
    LoaderComponent,
    WmDropdownComponent,
    TranslateModule,
  ],
})
export class ProblemReportingComponent implements OnDestroy {
  currentStep: 1 | 2 | 3 = 1;
  problemReportForm: FormGroup;

  isLoading: boolean = false;
  private _subscriptions: Subscription = new Subscription();
  constructor(
    protected wmService: WaterMeterService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.problemReportForm = new FormGroup({
      image: new FormControl<string>('', [Validators.required]),
      waterMeter: new FormControl<number | null>(
        this.wmService.currentWmValue?.meter_id || null,
        [Validators.required],
      ),
      description: new FormControl<string>('', [Validators.required]),
    });
    const currentWmSub = this.wmService.currentWm$.subscribe({
      next: (wm) => {
        this.problemReportForm.patchValue({
          waterMeter: wm?.meter_id,
        });
      },
      error: (e) => {
        console.error('Problem report error:', e);
      },
    });
    this._subscriptions.add(currentWmSub);
  }

  onSubmit() {
    if (this.problemReportForm.valid) {
      this.isLoading = true;
      this.wmService.reportProblem(this.problemReportForm.value).subscribe({
        next: async (_) => {
          this.problemReportForm.reset();
          this.problemReportForm.patchValue({
            waterMeter: this.wmService.currentWmValue?.meter_id,
          });
          this.currentStep = 1;
          this.isLoading = false;
        },
        error: (e) => {
          console.error('Problem report error:', e);
        },
      });
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  async selectWaterMeter(wmSn: string) {
    const wms = await firstValueFrom(this.wmService.wms$);
    const wm = wms?.find((wm) => wm.device_sn === wmSn);
    this.problemReportForm.patchValue({
      waterMeter: wm?.meter_id,
    });
    this.wmService.setCurrentWaterMeter(wmSn);
  }

  async uploadFile(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    try {
      const base64 = await this.getBase64(file);
      this.problemReportForm.patchValue({
        image: base64,
      });

      this.problemReportForm.get('image')?.updateValueAndValidity();
    } catch (error) {
      this.toastr.error('An error occurred', 'Error');
    }
  }

  getBase64(file: File): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  goToNextStep() {
    this.currentStep++;
  }

  goToPreviousStep() {
    this.currentStep--;
  }
}
