import { Type } from '@angular/core';

import { BaseModalComponent } from './modal.models';
import { EnableWmMonitoringModalComponent } from '../custom-modals/enable-wm-monitoring-modal/enable-wm-monitoring-modal.component';
import { MobileAlarmModalComponent } from '../custom-modals/mobile-alarm-modal/mobile-alarm-modal.component';
import { RenameWatermeterModalComponent } from '../custom-modals/rename-watermeter-modal/rename-watermeter-modal.component';
import { SendWmMeasurementsModalComponent } from '../custom-modals/send-wm-measurements-modal/send-wm-measurements-modal.component';
export interface ModalComponentMap {
  'rename-modal': Type<RenameWatermeterModalComponent>;
  'measurements-modal': Type<SendWmMeasurementsModalComponent>;
  'monitor-modal': Type<EnableWmMonitoringModalComponent>;
  'alarm-list-modal': Type<MobileAlarmModalComponent>;
}
export const modalComponentMap: { [key: string]: Type<BaseModalComponent> } = {
  'rename-modal': RenameWatermeterModalComponent,
  'measurements-modal': SendWmMeasurementsModalComponent,
  'monitor-modal': EnableWmMonitoringModalComponent,
  'alarm-list-modal': MobileAlarmModalComponent,
};
