@if (wmService.currentWm$ | async; as wm) {
  <aup-dropdown [customTriggerZIndex]="1001" [offsetY]="-30">
    <button
      dropdown-trigger
      [ngClass]="classList ? classList : 'mb-6 flex w-full items-center justify-between rounded-full bg-white px-6 py-5 font-normal text-black text-md'"
      type="button">
      <div>
        {{'dropdown.selected' | translate}}: <span  class="font-bold text-brand-primary">{{ wm.device_sn }}</span>
      </div>
      <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
      </svg>
    </button>
      <!-- Dropdown menu -->
      <div dropdown-content class="pt-2 bg-[#EBEBEB] rounded-b-[27px] shadow w-[340px] drop-shadow-xl">
        <ul content class="px-6 text-sm" aria-labelledby="selectWmDropdown">
          @for (wmRecord of wmService.wms$ | async; track wmRecord.device_sn; let i = $index){
            <li
              [tabindex]="i"
              (keydown.enter)="onMeterClick(wmRecord.device_sn)"
              (click)="onMeterClick(wmRecord.device_sn)"
              [ngClass]="{'text-md font-bold cursor-pointer': true, 'py-8': i === 0, 'pb-8': !wmRecord.alias, 'pb-2': wmRecord.alias }">
              <div class="flex flex-row items-start justify-start">
                <div class="flex h-full w-fit items-center justify-center">
                  @if (wmRecord.last_log && wmRecord.last_log.alarms.length > 0) {
                    <aup-icon class="h-7 w-7 fill-brand-red" name="temporary_error"></aup-icon>
                  } @else {
                    <aup-icon name="checkmark-success" class="h-6 w-6"></aup-icon>
                  }
                </div>
                <div class="flex flex-col">
                  <a class="block px-1">{{wmRecord.device_sn}}</a>
                  @if (wmRecord.alias) {
                    <a class="block px-1 font-normal leading-1">{{wmRecord.alias}}</a>
                  }
                </div>
              </div>
            </li>
          }
        </ul>
    </div>
  </aup-dropdown>
}
