<aup-modal [id]="id">
  <div class="flex flex-wrap items-center justify-between modal-header bg-brand-primary px-[16px] py-[25px] sm:rounded-t-[27px] sm:px-[33px]">
    <h2 class="text-xl font-bold text-white">{{'modals.measurements_wm.title' | translate}}</h2>
    <svg (click)="modal.closeModal(id)" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </div>
  <div class="inline-block h-full w-full items-center justify-center bg-white px-7 pb-6 text-lg text-gray-500 modal-body rounded-b-[27px] sm:flex sm:flex-col">
        <form class="mt-6 w-full" [formGroup]="sendMeasurementsForm" (ngSubmit)="onSubmit()" >
          @switch (currentStep) {
            @case (1) {
              <div class="mb-6 flex flex-col justify-center text-black">
                <p class="text-center text-base">{{'modals.measurements_wm.step_1' | translate}}</p>
                <p class="text-center text-xl font-bold">{{'modals.measurements_wm.step_1_title' | translate}}</p>
              </div>
              <div class="mb-12 flex w-full items-center justify-center">
                <label for="image" class="flex h-44 w-full cursor-pointer flex-col items-center justify-center border-[1px] border-font-secondary rounded-input hover:bg-gray-100">
                    @if(sendMeasurementsForm.controls['image'].value) {
                      <img [src]="sendMeasurementsForm.controls['image'].value" alt="Measurement image" class="h-full w-full object-contain rounded-input">
                    } @else {
                        <div class="flex flex-col items-center justify-center pt-5 pb-6 text-lg">
                            <aup-icon name="meter-detail" class="h-16 w-20 fill-font-secondary" ></aup-icon>
                            <p class="mb-2 text-lg text-gray-500 dark:text-gray-400">{{'modals.measurements_wm.upload_image_part_1' | translate}} <span class="text-brand-primary">{{'modals.measurements_wm.upload_image_part_2' | translate}}</span> {{'modals.measurements_wm.upload_image_part_3' | translate}} </p>
                        </div>
                    }
                  <input #fileInput id="image" (change)="uploadFile($event)" type="file" accept="image/*, image/heic" class="hidden" />
                </label>
              </div>
              <div class="flex flex-row items-center justify-center bg-white p-2">
                <aup-modal-button text="{{'modals.buttons.cancel' | translate}}" type="cancel" (clicked)="closeModal()" ></aup-modal-button>
                <aup-modal-button text="{{'modals.buttons.next' | translate}}" type="proceed" (clicked)="goToNextStep()" [disabled]="!sendMeasurementsForm.controls['image'].valid" ></aup-modal-button>
              </div>
            }
            @case (2) {
                <div class="mb-6 flex flex-col justify-center text-black">
                    <p class="text-center text-base">{{'modals.measurements_wm.step_2' | translate}}</p>
                    <p class="text-center text-xl font-bold">{{'modals.measurements_wm.step_2_title' | translate}}</p>
                </div>
                <div class="flex w-full flex-col items-center justify-center">
                    <input type="text" class="mb-4 text-black modal-input" id="counter" formControlName="counter" autocomplete="off" placeholder="1234567890">
                </div>
              <div class="flex flex-row items-center justify-center bg-white p-2">
                <aup-modal-button text="{{'modals.buttons.back' | translate}}" type="cancel" (clicked)="goToPreviousStep()"></aup-modal-button>
                <aup-modal-button text="{{'modals.buttons.save' | translate}}" type="proceed" [disabled]="!sendMeasurementsForm.valid || loading"></aup-modal-button>
              </div>
            }
          }

        </form>
      </div>

</aup-modal>

