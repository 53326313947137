<div class="flex min-h-screen flex-col">
  <!-- Header -->
  <div class="flex">
    <aup-header class="w-full"></aup-header>
  </div>

  <!-- Content Area with Sidebar and Main Content -->
  <div class="flex flex-1">
    <!-- Sidebar -->
    <aup-sidenav class="w-14 desktop:min-w-64 bg-[#FCFCFC] drop-shadow-lg"></aup-sidenav>

    <!-- Main Content Area -->
    <div class="flex-1 bg-[#EFF1F5] p-4 md:p-8">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
