<!--<aup-loader *ngIf="loading"></aup-loader>-->

<div class="overflow-x-hidden h-screen overflow-y-auto bg-[radial-gradient(circle,_#0B42AC,_#062156)] flex flex-col justify-start items-center text-font-white">
  <div class="mt-10 mb-10 w-1/2 max-w-[250px] sm:mt-16">
    <img src="assets/images/logo-light.svg" alt="vodomer logo">
  </div>
  <div class="px-4 text-center max-w-[472px] sm:text-center">
    <h1 class="mb-6 text-center text-3xl font-bold sm:text-xl">{{'confirm_email.title' | translate }}</h1>
    <p class="mb-10 text-lg sm:mb-3 sm:text-sm">
      {{'confirm_email.part_1' | translate}} <br/><span class="font-bold">{{'confirm_email.part_link' | translate}}</span> <br />
    </p>
    <p class="text-lg sm:text-sm">
      {{'confirm_email.part_2' | translate}}
    </p>
  </div>
</div>

