<div class="min-h-[30.5px]" [ngClass]="{'mb-2 mt-2': !removeInnerSpacings}" >
  @for (control of controls; track control.value) {
    @if (control.errors && control.touched) {
      @for (error of errorMessages; track error) {
        <p class="text-base text-red-500">
          {{ error }}
        </p>
      }
    }
<!--    <ng-container *ngIf="control.errors && control.touched">-->

<!--    </ng-container>-->
  }

</div>

