@if (wmService.isLoading) {
<aup-loader [isDark]="true"></aup-loader>

} @else {
@if (wmService.currentWm$ | async; as wm) {
  @if (wmService.isMultipleWm() | async) {
  <div class="max-w-[340px]">
    <aup-wm-dropdown id="123123" (wmClick)="wmService.setCurrentWaterMeter($event)"></aup-wm-dropdown>
  </div>
  }

  <div class="grid grid-cols-6 gap-4">
    <!-- WATER METER -->
    <div class="desktop:col-span-3 laptop:col-span-3 tablet:col-span-3 col-span-6 4xl:col-span-2">
      <div class="mb-2 text-2xl font-bold title">{{ 'dashboard.water_meter' | translate }}</div>
      <div class="relative text-white base-bg text-[1rem] xl:text-[1.2rem]"
        [ngClass]="{'blue': !currentWmHasAlarms(wm.last_log?.alarms?.length ?? null), 'red': currentWmHasAlarms(wm.last_log?.alarms?.length ?? null) }">
        <div class="absolute top-2 right-2 flex items-end justify-end fill-white">
          <aup-dropdown>
            <button dropdown-trigger type="button">
              <aup-icon name="settings" class="h-12 w-12 !fill-whit e"></aup-icon>
            </button>
            <div dropdown-content id="settingsDropDown" class="w-fit rounded-lg bg-white shadow divide-y divide-gray-100">
              <ul class="py-2 text-base text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                <li class="cursor-pointer">
                  <a tabindex="9"
                     (keydown.enter)="openWaterMeterModal('rename-modal')"
                     (click)="openWaterMeterModal('rename-modal')"
                     class="block px-4 py-2 hover:bg-gray-100">{{
                    'components.water_meter.alias' | translate }}</a>
                </li>
                <li class="cursor-pointer">
                  <a tabindex="10"
                     (keydown.enter)="openWaterMeterModal('measurements-modal')"
                     (click)="openWaterMeterModal('measurements-modal')"
                     class="block px-4 py-2 hover:bg-gray-100">{{
                    'components.water_meter.send_measurement' | translate }}</a>
                </li>
                <li class="cursor-pointer">
                  <a tabindex="11"
                     (keydown.enter)="openWaterMeterModal('monitor-modal')"
                     (click)="openWaterMeterModal('monitor-modal')"
                     class="block px-4 py-2 hover:bg-gray-100">{{
                    'components.water_meter.own_monitoring' | translate }}</a>
                </li>
                <li class="cursor-pointer">
                  <a
                    tabindex="12"
                    [routerLink]="'/meter-info/' + wm.device_sn"
                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{
                    'components.water_meter.show_info' | translate }}</a>
                </li>
              </ul>
            </div>
          </aup-dropdown>
        </div>
        <div class="">
          <div class="mt-2 text-center">{{ 'components.water_meter.number' | translate }}</div>
          <div class="text-center font-bold text-[1.6rem] lg:text-[1.6rem] xl:text-[1.8rem]">{{ wm.device_sn }}</div>
          <div class="mt-2 text-center">{{ (wm.type ?? '') + ' ' + wm.water_meter_diameter }}</div>
          @if (wm.alias) {
          <div class="text-center">{{ wm.alias }}</div>
          }
        </div>
        @if (wm.last_log) {
        <div class="my-4 flex aspect-square w-full flex-col items-center justify-center bg-contain bg-center bg-no-repeat"
          [ngClass]="{' default-meter-bg': !currentWmHasAlarms(wm.last_log.alarms.length),' alarm-meter-bg': currentWmHasAlarms(wm.last_log.alarms.length)}">

          <!-- meter data -->
          <div class="flex flex-col items-center">
            <div class="">{{ 'components.water_meter.last_readout' | translate }}:</div>
            <div class="font-bold">{{ wm.last_log.readout_time | date: 'medium'}}</div>
          </div>
          <div class="rounded-lg p-1 text-center w-[70%]"
            [ngClass]="{'bg-[#062E96]':!currentWmHasAlarms(wm.last_log.alarms.length),'bg-[#6D070F]':currentWmHasAlarms(wm.last_log.alarms.length)}">
            <span class="ml-2 font-bold text-[1.6rem] xl:text-[1.8rem]">{{ wm.last_log.counter | numberMutator }}
              m<sup>3</sup></span>
          </div>
          <div
            tabindex="13"
            (keydown.enter)="openWaterMeterModal('alarm-list-modal')"
            (click)="openWaterMeterModal('alarm-list-modal')" class="sm:hidden">
            <aup-icon class="h-8 w-8 fill-white" [name]="'temporary_error'"></aup-icon>
          </div>
          <div class="hidden w-[50%] sm:mt-4 sm:flex sm:flex-col sm:items-center sm:justify-center" style="">
            @if (currentWmHasAlarms(wm.last_log.alarms.length)) {
            <aup-slider [arrows]="false" [infinite]="true" [autoplay]="true" [dots]="true">
              @for (alarm of wm.last_log.alarms; track alarm.id) {
                <div class="flex flex-col items-center justify-center" *aupNgSliderSlide>
                  <div class="mb-0">{{ ('alarm.' + alarm.name) | translate }}</div>
                  <aup-icon class="fill-white w-[4vw]" [name]="alarm.name"></aup-icon>
                </div>
              }
            </aup-slider>
            } @else {
              <div class="mb-0 text-brand-primary-darker">{{ 'alarm.no_warnings' | translate }}</div>
              <aup-icon class="m-0 p-0 w-[4vw] fill-brand-primary-darker" name="temporary_error"></aup-icon>
            }
          </div>
        </div>
        }
        <div class="flex flex-col justify-center">
          <p class="mb-2 text-center">
            {{ 'components.water_meter.average_per' | translate }}
            {{ (wm.frequency === 'yearly' ? 'date.year' : wm.frequency === 'monthly' ? 'date.month' : wm.frequency ===
            'daily' ? 'date.day' : '') | translate }}
            :
          </p>
          <p class="text-center font-bold text-[2rem] xl:text-[2.8rem]">{{ wm.avg_per_unit | numberMutator }}
            m<sup>3</sup></p>
        </div>
      </div>
    </div>

    <!-- GRAPH -->
    <div class="tablet:col-span-3 laptop:col-span-3 desktop:col-span-3 col-span-6 4xl:col-span-4">
      <div class="mb-2 text-2xl font-bold title">{{ 'dashboard.measurement_stats' | translate }}</div>
      <div class="p-4 base-bg">
        <div class="chart-container">
          <aup-chart [wm]="wm"></aup-chart>
        </div>
      </div>
    </div>
  </div>
} @else {
    <aup-add-water-meter></aup-add-water-meter>
  }
}
