import { Pipe, PipeTransform } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'numberMutator',
  standalone: true,
})
export class NumberMutatorPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: unknown, ..._: unknown[]): string | null {
    if (
      typeof value === 'number' ||
      (typeof value === 'string' && !isNaN(Number(value)))
    ) {
      let formattedValue = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(value));

      // Default separators
      let decimalSeparator = ',';
      let thousandsSeparator = '.';

      // Check for custom user settings
      if (this.authService.userValue && this.authService.userValue.settings) {
        const settings = this.authService.userValue.settings;
        if (settings.decimal_separator) {
          decimalSeparator = settings.decimal_separator;
        }
        if (settings.thousands_separator) {
          thousandsSeparator = settings.thousands_separator;
        }
      }

      // Replace default separators with custom separators
      formattedValue = formattedValue
        .replace(',', 'TEMP_DECIMAL')
        .replace('.', 'TEMP_THOUSAND')
        .replace('TEMP_DECIMAL', decimalSeparator)
        .replace('TEMP_THOUSAND', thousandsSeparator);

      return formattedValue;
    }
    return null;
  }
}
