<div class="flex justify-between items-center bg-[#EFF1F5] p-1.5 mb-2 h-full rounded-[11px] tablet:rounded-full">
  <div class="flex 3-5xl:hidden">
    <aup-dropdown [offsetY]="15">
      <div dropdown-trigger>
        <button
          class="flex items-center tablet:rounded-full bg-white px-5 py-1 text-center font-medium uppercase text-black rounded-[11px] text-md"
          type="button"
        >
          <div>
            @if (selectorState.state === 'yearly') {
              {{ 'components.chart.yearly' | translate }}
            } @else if (selectorState.state === 'monthly') {
              {{ 'components.chart.monthly' | translate }}
            } @else if (selectorState.state === 'daily') {
              {{ 'components.chart.daily' | translate }}
            } @else if (selectorState.state === 'hourly') {
              {{ 'components.chart.hourly' | translate }}
            } @else {
              {{ 'components.chart.select' | translate }}
            }
          </div>
          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
          </svg>
        </button>
      </div>
      <div dropdown-content class="flex flex-col bg-[#EFF1F5]">
        <button
          (click)="selectYear()"
          [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'yearly' }"
          class="cursor-pointer border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
          {{ 'components.chart.yearly' | translate }}
        </button>
        <button
          (click)="selectMonth()"
          [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'monthly' }"
          class="cursor-pointer border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
          {{ 'components.chart.monthly' | translate }}
        </button>
        <button
          (click)="selectDay()"
          [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'daily' }"
          class="cursor-pointer border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
          {{ 'components.chart.daily' | translate }}
        </button>
        <button
          (click)="setHourlyDataset()"
          [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'hourly' }"
          class="cursor-pointer border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
          {{ 'components.chart.hourly' | translate }}
        </button>
      </div>
    </aup-dropdown>
  </div>
  <div class="3-5xl:flex hidden 3-5xl:flex-row flex-col">
    <button
      (click)="selectYear()"
      [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'yearly' }"
      class="mr-1 cursor-pointer rounded-full border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
      {{ 'components.chart.yearly' | translate }}
    </button>
    <button
      (click)="selectMonth()"
      [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'monthly' }"
      class="mr-1 cursor-pointer rounded-full border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
      {{ 'components.chart.monthly' | translate }}
    </button>
    <button
      (click)="selectDay()"
      [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'daily' }"
      class="mr-1 cursor-pointer rounded-full border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
      {{ 'components.chart.daily' | translate }}
    </button>
    <button
      (click)="setHourlyDataset()"
      [ngClass]="{ '!text-brand-primary bg-white hover:!text-font-white': selectorState.state === 'hourly' }"
      class="mr-1 cursor-pointer rounded-full border-none px-3 py-1 uppercase text-gray-400 hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">
      {{ 'components.chart.hourly' | translate }}
    </button>
  </div>
  <div class="flex h-full mobile:flex-row flex-col items-center justify-center">
    @if (selectorState.state === 'daily' || selectorState.state === 'hourly') {
      <aup-dropdown>
        <button
          dropdown-trigger
          class="mobile:mr-2 mobile:mb-0 mb-2 flex h-full items-center tablet:rounded-full bg-white px-5 py-1 text-center font-medium uppercase text-black rounded-[11px] text-md"
          type="button"
        >
          <div>
            @if (selectorState.day) {
              {{ selectorState.day }}
            } @else {
              {{ 'components.chart.select_day' | translate }}
            }
          </div>
          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
          </svg>
        </button>
        <div dropdown-content id="daySelectorDropdown" class="w-20 overflow-y-auto rounded-lg bg-white shadow divide-y divide-gray-100 max-h-[300px] dark:bg-gray-700">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDelayButton">
            @if (availableDays.length > 0) {
              @for (day of availableDays; track day; let i = $index) {
                <li>
                  <a
                    [tabindex]="i + 1"
                    (keydown.enter)="selectDay(day)"
                    (click)="selectDay(day)"

                    class="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{day}}
                  </a>
                </li>
              }
            } @else {
              <li>
                <a class="block cursor-not-allowed px-4 py-2 text-gray-400">No data</a>
              </li>
            }
          </ul>
        </div>
      </aup-dropdown>
    }
    @if (selectorState.state === 'monthly' || selectorState.state === 'daily' || selectorState.state === 'hourly') {
      <aup-dropdown>
        <button
          dropdown-trigger
          class="mobile:mr-2 mobile:mb-0 mb-2 flex h-full items-center tablet:rounded-full bg-white px-5 py-1 text-center font-medium uppercase text-black rounded-[11px] text-md"
          type="button"
        >
          <div>
            @if (selectorState.month) {
              {{ selectorState.month }}
            } @else {
              {{ 'components.chart.select_month' | translate }}
            }
          </div>
          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
          </svg>
        </button>
        <div dropdown-content id="monthSelectorDropdown" class="w-20 rounded-lg bg-white shadow divide-y divide-gray-100 dark:bg-gray-700">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDelayButton">
            @if (availableMonths.length > 0) {
              @for (month of availableMonths; track month; let i = $index) {
                <li>
                  <a
                    [tabindex]="i"
                    (keydown.enter)="selectMonth(month)"
                    (click)="selectMonth(month)"
                    class="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{month}}
                  </a>
                </li>
              }
            } @else {
              <li>
                <a class="block cursor-not-allowed px-4 py-2 text-gray-400">No data</a>
              </li>
            }
          </ul>
        </div>
      </aup-dropdown>
    }
    @if (selectorState.state === 'monthly' || selectorState.state === 'daily' || selectorState.state === 'hourly') {
      <aup-dropdown>
        <button
          dropdown-trigger
          class="flex h-full items-center tablet:rounded-full bg-white px-5 py-1 text-center font-medium uppercase text-black rounded-[11px] text-md"
          type="button"
        >
          <div>
            @if (selectorState.year) {
              {{ selectorState.year }}
            } @else {
              {{ 'components.chart.select_year' | translate }}
            }
          </div>
          <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
          </svg>
        </button>
        <div dropdown-content id="yearSelectorDropdown" class="w-20 rounded-lg bg-white shadow divide-y divide-gray-100 dark:bg-gray-700">
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDelayButton">
            @for (year of availableYears; track year; let i = $index) {
              <li>
                <a
                  [tabindex]="i"
                  (keydown.enter)="selectYear(year)"
                  (click)="selectYear(year)"
                  class="block cursor-pointer px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{year}}
                </a>
              </li>
            }
          </ul>
        </div>
      </aup-dropdown>
    }
  </div>
</div>

<div id="chart-container" class="relative h-full chart-container">
  <div class="relative mb-4 h-full w-full min-h-[300px]">
    <canvas #chart id="chart">
      {{ chartJs }}
    </canvas>
    @if (loading()) {
        <aup-loader [isDark]="true" ></aup-loader>
    }
  </div>
  @if (this.selectorState.tableData) {
    <div class="relative overflow-x-auto h-[200px]">
      <table class="w-full text-left rtl:text-right text-sm text-gray-500">
        <thead class="text-md text-gray-400 uppercase  bg-[#EFF1F5] sticky top-0">
          <tr>
            @for (header of this.selectorState.tableData.tableHeaders; track header) {
              <th scope="col" class="px-6 py-3">
                {{ header | translate }}
              </th>
            }
          </tr>
        </thead>
        <tbody>
          @for (row of this.selectorState.tableData.tableBody; track row) {
            @if (row) {
              <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                <th scope="row" class="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white">
                  {{ row?.readout_time | date:'medium' }}
                </th>
                <td class="px-6 py-4">
                  {{ row?.counter | numberMutator  }}
                </td>
                <td class="px-6 py-4">
                  {{ row?.consumption | numberMutator }}
                </td>
              </tr>
            }
          }
        </tbody>
      </table>
    </div>
    <div class="relative my-2 laptop:mb-0 flex h-full w-full justify-end max-h-[7%]">
      <button class="laptop:w-auto w-full primary-button" (click)="exportToExcel()">{{'components.chart.export' | translate}}</button>
    </div>
  }
</div>
