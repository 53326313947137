import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IUserSetting, IUserUpdateForm } from '../../../models/IUser.types';
import { NumberMutatorPipe } from '../../../pipes/number-mutator.pipe';
import { AuthService } from '../../../services/auth.service';
import { SliderComponent } from '../../utilities/sliders/slider/slider.component';

// components

@Component({
  selector: 'aup-account',
  standalone: true,
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
  imports: [
    TranslateModule,
    AsyncPipe,
    ReactiveFormsModule,
    SliderComponent,
    NumberMutatorPipe,
  ],
})
export class AccountComponent implements OnInit, OnDestroy {
  updateUserDataForm: FormGroup = new FormGroup({
    firstname: new FormControl<string>(''),
    lastname: new FormControl<string>(''),
    phone_number: new FormControl<string>(''),
    current_password: new FormControl<string>(''),
    password: new FormControl<string>(''),
    gdpr_agreement: new FormControl<boolean>(false),
  });

  private _subscriptions: Subscription = new Subscription();
  constructor(protected authService: AuthService) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this.authService.user$.subscribe({
        next: (user) => {
          this.updateUserDataForm.patchValue({
            firstname: user?.firstname,
            lastname: user?.lastname,
            phone_number: user?.phone_number,
            gdpr_agreement: user?.gdpr_agreement === 1,
          });
        },
        error: (error) => {
          console.error('User error:', error);
        },
      }),
    );

    return;
  }

  onSubmit(): void {
    if (this.updateUserDataForm.valid) {
      this.authService.updateUserData(this.getDirtyFields()).subscribe();
    }
  }

  //get only dirty fields from form
  getDirtyFields(): Partial<IUserUpdateForm> {
    const dirtyFields: IUserUpdateForm = {};
    Object.keys(this.updateUserDataForm.controls).forEach((key: string) => {
      const control = this.updateUserDataForm.get(key);
      if (control?.dirty) {
        dirtyFields[key as keyof IUserUpdateForm] = control.value;
      }
    });
    return dirtyFields;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
