import {
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { initFlowbite } from 'flowbite';
import { filter, Subscription } from 'rxjs';

@Directive({
  selector: '[aupFlowbiteMark]',
  standalone: true,
})
export class FlowbiteMarkDirective implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  constructor(
    private _el: ElementRef,
    private _router: Router,
  ) {
    const routerSub = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        console.log('Router event:', event);
      });
    this._subscriptions.add(routerSub);
  }

  ngOnInit(): void {
    initFlowbite();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    // Prevent the default action to avoid any unwanted behavior, if necessary
    // event.preventDefault();

    // Log the target element or perform any action needed
    console.log('Clicked element:', this._el.nativeElement);

    // If you want to perform actions based on the clicked inner elements, you can use event.target
    console.log('Event target:', event.target);
  }
}
