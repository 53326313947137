import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'aup-loader',
  standalone: true,
  imports: [NgStyle, NgClass],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input() classList: string = '';
  @Input() isDark: boolean = false;
}
