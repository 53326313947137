import { AsyncPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FlowbiteMarkDirective } from '../../../../directives/flowbite-mark.directive';
import { WaterMeterService } from '../../../../services/water-meter.service';
import { IconComponent } from '../../icon/icon.component';
import { DropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'aup-wm-dropdown',
  standalone: true,
  imports: [
    AsyncPipe,
    IconComponent,
    NgClass,
    FlowbiteMarkDirective,
    DropdownComponent,
    TranslateModule,
  ],
  templateUrl: './wm-dropdown.component.html',
  styleUrl: './wm-dropdown.component.scss',
})
export class WmDropdownComponent {
  @Input() id!: string;
  @Input() classList: string = '';
  @Output() wmClick: EventEmitter<string> = new EventEmitter();

  constructor(protected wmService: WaterMeterService) {}

  public onMeterClick(wm_sn: string) {
    this.wmClick.emit(wm_sn);
  }
}
