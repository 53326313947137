<aup-modal [id]="id">
  <div class="flex flex-wrap items-center justify-between modal-header bg-brand-primary px-[16px] py-[25px] sm:rounded-t-[27px] sm:px-[33px]">
    <h2 class="text-xl font-bold text-white">Edit Water Meter Alias</h2>
    <svg (click)="modal.closeModal(id)" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </div>
  <div class="inline-block h-full w-full items-center justify-center bg-white px-7 pb-6 text-lg text-gray-500 modal-body rounded-b-[27px] sm:flex sm:flex-col">
    <div class="mt-4 flex flex-row items-center justify-center">
      @for (wm of wmService.getWaterMetersWithAlarms() | async; track wm.device_sn) {
        <div class="flex w-full flex-col">
          <span class="text-lg font-bold text-black">{{wm.device_sn}}</span>
          <div class="mb-2 ml-4 flex w-full flex-row items-center justify-between">
            @for (alarm of wm.last_log?.alarms; track alarm) {
              <span class="text-base text-brand-red">{{ 'alarm.' + alarm.name | translate}}</span>
              <aup-icon class="h-8 w-8 fill-brand-red" [name]="alarm.name"></aup-icon>
            }
          </div>
        </div>
      }
    </div>
  </div>
</aup-modal>

