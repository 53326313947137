<nav class="flex h-full w-full">
  <div class="h-full w-full justify-center px-0 desktop:px-8 py-4 desktop:py-10">
    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'dash'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.dashboard' | translate }}</span>
    </a>
    <a routerLink="/meter-info" routerLinkActive="active" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'meter'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.meter_info' | translate }}</span>
    </a>
    <a routerLink="/problem-reporting" routerLinkActive="active" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'problem'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.problem_reporting' | translate }}</span>
    </a>
    <a routerLink="/announcements" routerLinkActive="active" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'announcements'" [class]="'flex align-middle justify-center items-center'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.announcements' | translate }}</span>
    </a>
    <a routerLink="/faq" routerLinkActive="active" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'faq'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.faq' | translate }}</span>
    </a>
    <a routerLink="/account" routerLinkActive="active" class="mb-4 flex cursor-pointer items-center desktop:justify-start justify-center group">
      <aup-icon class="icon-sidebar group-[.active]:fill-brand-primary" [name]="'profile'"></aup-icon>
      <span class="ml-4 desktop:flex hidden font-bold text-gray-400 group-hover:text-font-base group-[.active]:text-brand-primary">{{ 'nav.main.account' | translate }}</span>
    </a>
  </div>
</nav>
