import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from '../../../services/auth.service';
import { FormFieldErrorComponent } from '../../utilities/form-field-error/form-field-error.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';

@Component({
  selector: 'aup-email-confirmation',
  standalone: true,
  imports: [
    FormFieldErrorComponent,
    FormsModule,
    LoaderComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './email-confirmation.component.html',
  styleUrl: './email-confirmation.component.scss',
})
export class EmailConfirmationComponent implements OnInit {
  token: string | null = null;
  message: string = 'Please check your email to verify your account.';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    const tokenFromPath = this.activatedRoute.snapshot.paramMap.get('token');
    this.token = tokenFromPath;

    if (this.token) {
      this.verifyEmail(this.token);
    }
  }

  verifyEmail(token: string): void {
    this.authService.verifyEmail(token).subscribe({
      next: (_) => {
        // left that until the notification will be implemented
        this.message =
          'Your email has been successfully verified. You can now login.';
      },
      error: (e) => {
        this.message =
          'There was an error verifying your email. Please try again or contact support.';
        console.error('Email verification error:', e);
      },
    });
  }
}
