import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

// components
import { IconComponent } from '../../utilities/icon/icon.component';

@Component({
  selector: 'aup-sidenav',
  standalone: true,
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  imports: [IconComponent, TranslateModule, RouterLink, RouterLinkActive],
})
export class SidenavComponent {}
