import { AsyncPipe } from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { initFlowbite } from 'flowbite';

import { AnnouncmentsService } from '../../../services/announcments.service';
import { LoaderComponent } from '../../utilities/loader/loader.component';
import {Subscription} from "rxjs";
import {IAnnouncement, IFaq} from "../../../models/IAnnouncemments.types";

@Component({
  selector: 'aup-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  imports: [TranslateModule, AsyncPipe, LoaderComponent],
})
export class FaqComponent implements OnInit, OnDestroy {
  public faqs: Array<IFaq> = [];
  private _subscriptions = new Subscription();
  constructor(protected ancService: AnnouncmentsService) {}

  ngOnInit() {
    this._subscriptions.add(
      this.ancService.faqs$.subscribe((faqs) => {
        if (!faqs) return;
        this.faqs = faqs;
        setTimeout(()=>initFlowbite());
      }),
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onClick() {
    initFlowbite();
  }
}
