@if (loading) {
  <aup-loader></aup-loader>
}

<div class="w-full h-full min-h-screen bg-[radial-gradient(circle,_#4DA0FF_0,_#00187D_70%)] sm:bg-[radial-gradient(circle,_#0B42AC,_#062156)] flex flex-col items-center justify-center">
  <div class="mt-0 flex w-full flex-col items-center justify-start wrapper max-w-[550px] text-font-white sm:my-10">
    <!-- LOGO -->
    <div class="w-full px-8 max-w-[250px]">
      <img src="assets/images/logo-light.svg" alt="vodomer logo">
    </div>

    <!-- LOGIN -->
    <div class="w-full flex flex-col justify-center items-center my-8 sm:my-4 sm:aspect-square sm:bg-[url('assets/images/login-page-meter.svg')] bg-no-repeat bg-center bg-contain">
      <div class="flex h-fit flex-col justify-center w-[80%]">
        <h1 class="mx-auto self-center text-center text-xl font-bold">{{ 'login.sign_in_text' | translate }}</h1>
        <form [formGroup]="loginForm" autocomplete="on" (ngSubmit)="onSubmit()" class="mt-2 text-font-base sm:mt-6">
          <div class="flex flex-col items-center justify-center">
            <label class="text-base text-font-white" for="email">{{ 'login.user_name' | translate }}</label>
            <input class="h-12 w-full text-center text-sm outline-none text-font-white bg-brand-primary-darker rounded-[9px] px-[32px] sm:h-10 sm:w-auto" type="email" id="email" formControlName="email" autocomplete="email" />
            <aup-form-field-error [removeInnerSpacings]="true" [controls]="[loginForm.controls['email']]" [errors]="{'required': 'Email name is required', 'invalid': 'Email is invalid', 'invalid-cred': 'Invalid login or password'}" ></aup-form-field-error>
          </div>
          <div class="flex flex-col items-center justify-center">
            <label class="text-base text-font-white" for="password">{{ 'login.password' | translate }}</label>
            <input class="h-12 w-full text-center text-sm outline-none text-font-white bg-brand-primary-darker rounded-[9px] px-[32px] sm:h-10 sm:w-auto" type="password" id="password" formControlName="password" autocomplete="current-password" />
            <aup-form-field-error [removeInnerSpacings]="true" [controls]="[loginForm.controls['password']]" [errors]="{'required': 'Password name is required', 'invalid-cred': 'Invalid login or password'}" ></aup-form-field-error>
          </div>
          <div class="mb-2 flex items-center justify-center">
            <input type="checkbox" id="remember-me" class="form-checkbox custom-checkbox" formControlName="stayLoggedIn" />
            <label class="ml-2 text-base text-white" for="remember-me">{{ 'login.remember_me' | translate }}</label>
          </div>
          <div class="flex justify-center">
            <button class="outlined-button">{{ 'login.sign_in' | translate }}</button>
          </div>
        </form>
      </div>
    </div>

    <!-- FORGOT PASSWORD -->
    <div class="flex flex-col justify-center">
      <a routerLink="/reset-password" class="mb-8 cursor-pointer text-center text-base text-white underline">{{ 'login.forgot_password' | translate }}</a>
      <p class="mb-4 text-center text-xl text-white">{{ 'login.new_user' | translate }}</p>
      <a routerLink="/register" class="w-full outlined-reverse-button sm:w-fit">{{ 'login.register' | translate }}</a>
    </div>
  </div>
</div>
