@if (loading) {
  <aup-loader ></aup-loader>
}
<div  class="h-screen overflow-x-hidden overflow-y-auto bg-[radial-gradient(circle,_#0B42AC,_#062156)] flex flex-col justify-start items-center text-font-white">
  <div class="mt-10 mb-10 w-1/2 max-w-[250px] sm:mt-16">
    <img src="assets/images/logo-light.svg" alt="vodomer logo">
  </div>
  @if (!token && !requestSent) {
    <div class="flex w-full flex-col justify-center text-center sm:max-w-[530px]">
      <h1 class="mb-16 text-xl font-bold">{{ 'reset_password.reset_password' | translate }}</h1>
      <form [formGroup]="initResetPasswordForm" (ngSubmit)="onInitResetPassword()" >
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="email">{{ 'reset_password.email' | translate }}</label>
          <input class="form-input-regular" type="email" id="email" formControlName="email" autocomplete="email" placeholder="{{'reset_password.email_placeholder' | translate}}" />
          <aup-form-field-error [controls]="[initResetPasswordForm.controls['email']]" [errors]="{
            'required': ('reset_password.email' | translate) + ' ' + ('reset_password.error.is_required' | translate),
            'email': ('reset_password.email' | translate) + ' ' + ('reset_password.error.is_invalid' | translate)
          }"></aup-form-field-error>
        </div>
        <div class="flex justify-center px-4 sm:px-0">
          <button class="w-full outlined-reverse-button sm:w-fit">{{ 'reset_password.reset_password' | translate }}</button>
        </div>
      </form>
    </div>
  } @else if (!token && requestSent) {
    <div class="flex w-full flex-col justify-center text-center sm:max-w-[530px]">
      <h1 class="mb-16 text-xl font-bold">{{ 'reset_password.reset_password' | translate }}</h1>
      <p>{{'reset_password.email_sent' | translate}} <span class="font-bold">{{ initResetPasswordForm.get('email')?.value }}</span></p>
    </div>
  } @else if (true) {
    <div class="flex w-full flex-col justify-center text-center sm:max-w-[530px]">
      <h1 class="mb-16 text-xl font-bold">{{ 'reset_password.reset_password' | translate }}</h1>
      <div class="flex w-full flex-col justify-center">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onNewPasswordSubmit()" class="w-full text-font-base">

          <div class="mx-4 flex flex-col items-center justify-center">
            <label class="text-base text-font-white mb-2.5" for="password">{{ 'reset_password.password' | translate }}</label>
            <input class="form-input-regular" type="password" id="password" formControlName="password" autocomplete="off" placeholder="{{'reset_password.password_placeholder' | translate}}" />
            <aup-form-field-error [controls]="[resetPasswordForm.controls['password']]" [errors]="{
              'required': ('reset_password.password' | translate) + ' ' + ('reset_password.error.is_required' | translate),
              'minlength': ('reset_password.error.minlength' | translate: {length: resetPasswordForm.get('password')?.getError('minlength')?.requiredLength})
            }"></aup-form-field-error>
          </div>
          <div class="mx-4 flex flex-col items-center justify-center">
            <label class="text-base text-font-white mb-2.5" for="confirmPassword">{{ 'reset_password.password_reenter' | translate }}</label>
            <input class="form-input-regular" type="password" id="confirmPassword" formControlName="confirmPassword" autocomplete="off" placeholder="{{'reset_password.password_reenter_placeholder' | translate}}" />
            <aup-form-field-error [controls]="[resetPasswordForm, resetPasswordForm.controls['confirmPassword']]" [errors]="{
              'required': ('reset_password.error.password_confirm' | translate),
              'passwordsMismatch': ('reset_password.error.password_mismatch' | translate)
            }"></aup-form-field-error>
          </div>

          <div class="flex justify-center px-4 sm:px-0">
            <button class="w-full outlined-reverse-button sm:w-fit">{{ 'reset_password.reset_password' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  }
</div>

