import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgClass, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  HostListener,
} from '@angular/core';

import { debounceTime, fromEvent, Subscription, take } from 'rxjs';

@Component({
  selector: 'aup-dropdown',
  standalone: true,
  imports: [NgStyle, NgClass],
  template: `
    <div
      #triggerElement
      tabindex="0"
      (keydown.enter)="toggleDropdown()"
      (click)="toggleDropdown()"
      [ngStyle]="{ position: 'relative' }"
    >
      <ng-content select="[dropdown-trigger]"></ng-content>
    </div>
    <ng-template #dropdownContent>
      <div
        tabindex="1"
        (keydown.enter)="toggleDropdown()"
        (click)="toggleDropdown()"
        class="dropdown-panel"
      >
        <ng-content select="[dropdown-content]"></ng-content>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .dropdown-panel {
        position: absolute;
        z-index: 10;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, OnDestroy {
  // Reference to the trigger element
  @ViewChild('triggerElement', { static: true, read: ElementRef })
  triggerElement!: ElementRef;
  // Reference to the dropdown content template
  @ViewChild('dropdownContent', { static: true })
  dropdownContentTemplate!: TemplateRef<never>;
  // Offset to adjust the overlay position
  @Input() offsetY: number = 0;
  // Custom z-index for the trigger element (To make trigger higher than overlay, it should be higher than 1000)
  @Input() customTriggerZIndex: number = 10;
  // Custom z-index for the overlay
  @Input() customOverlayZIndex: number = 1000;
  // Center the overlay by X axis
  @Input() centerByX: boolean = false;

  // OverlayRef instance to control the overlay
  private _overlayRef: OverlayRef | null = null;
  // Subscription to handle the backdrop click event
  private _backdropClickSubscription: Subscription | null = null;
  // Flexible position strategy to position the overlay
  private _positionStrategy!: FlexibleConnectedPositionStrategy;

  private _oldZIndex: string = '0';

  private _subscriptions = new Subscription();

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.createOverlay();
  }

  ngOnDestroy(): void {
    this.disposeOverlay();
    this._subscriptions.unsubscribe();
  }

  /**
   * Toggles the dropdown by opening or closing the overlay.
   * @public
   */
  public toggleDropdown(): void {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  private listenForCloseEvents(): void {
    // Subscription to close the dropdown when clicking on the backdrop
    const backdropSubscription = this._overlayRef
      ?.backdropClick()
      .subscribe(() => {
        this.closeDropdown();
      });

    // Subscription to close the dropdown on detachment (for any reason, e.g., another overlay opens)
    const detachmentSubscription = this._overlayRef
      ?.detachments()
      .subscribe(() => {
        this.closeDropdown();
      });

    // Subscription to close the dropdown when pressing the Escape key
    const keyboardSubscription = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(debounceTime(10))
      .subscribe((event) => {
        if (event.key === 'Escape') {
          this.closeDropdown();
        }
      });

    // Subscription to re-calculate and update the position of the overlay on window resize
    const resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => this._overlayRef?.updatePosition());

    // Subscription to handle scroll events to update the position
    const scrollSubscription = fromEvent(window, 'scroll')
      .pipe(debounceTime(10))
      .subscribe(() => {
        if (this._overlayRef?.hasAttached()) {
          this.recalculateOverlayPosition();
        }
      });

    // Collect subscriptions
    this._subscriptions.add(detachmentSubscription);
    this._subscriptions.add(backdropSubscription);
    this._subscriptions.add(keyboardSubscription);
    this._subscriptions.add(resizeSubscription);
    this._subscriptions.add(scrollSubscription);
  }

  /**
   * Creates an overlay with a flexible position strategy that positions the overlay below the trigger element.
   * @private
   */
  private createOverlay(): void {
    // Create a strategy that positions the overlay below the trigger
    this._positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.triggerElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ])
      .withFlexibleDimensions(true)
      .withPush(true);

    // Create the overlay with the strategy
    this._overlayRef = this.overlay.create({
      positionStrategy: this._positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    // When the overlay is attached, recalculate the position
    this._overlayRef
      .attachments()
      .pipe(take(1))
      .subscribe(() => {
        this.recalculateOverlayPosition();
      });
  }

  /**
   * Opens the dropdown by attaching the overlay to the trigger element.
   * @private
   */
  private openDropdown(): void {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._oldZIndex = window.getComputedStyle(
        this.triggerElement.nativeElement,
      ).zIndex;
      this.triggerElement.nativeElement.style.zIndex = this.customTriggerZIndex;
      // Your existing code for setting zIndex and attaching the portal
      const portal = new TemplatePortal(
        this.dropdownContentTemplate,
        this.viewContainerRef,
      );
      this._overlayRef.attach(portal);

      // Immediately listen for close events after the dropdown is opened
      this.listenForCloseEvents();
    }
  }

  /**
   * Recalculates the position of the overlay relative to the trigger element.
   * This method is designed to adjust the overlay's position dynamically based on the overlay content width,
   * the trigger element's width, and the viewport size to prevent the overlay from overflowing off the screen.
   * @private
   */
  private recalculateOverlayPosition(): void {
    // Get the bounding rectangle of the trigger element.
    if (this._overlayRef && this.triggerElement.nativeElement) {
      const triggerRect =
        this.triggerElement.nativeElement.getBoundingClientRect();

      // Safely access the overlay element's width, defaulting to 0 if not accessible.
      const contentWidth = this._overlayRef?.overlayElement.firstChild
        ? (this._overlayRef.overlayElement.firstChild as HTMLElement)
            .offsetWidth
        : 0;

      // Determine if the overlay content is wider than the trigger element.
      const isContentWider = contentWidth > triggerRect.width;

      // Calculate if the overlay, when positioned next to the trigger element,
      // would overflow the right edge of the viewport.
      const viewportWidth = window.innerWidth;
      let offsetX = 0;
      // Determine if the overlay content overflows the left edge of the viewport
      const leftViewportOverflow = triggerRect.left - contentWidth < 0;
      const rightViewportOverflow =
        triggerRect.right + contentWidth > viewportWidth;

      // Adjust offsetX based on overflow scenarios
      if (isContentWider) {
        if (rightViewportOverflow && !leftViewportOverflow) {
          const rightOverflowAmount =
            triggerRect.right + contentWidth - viewportWidth;
          // If overflowing to the right but not to the left, adjust offsetX to move the overlay left
          offsetX =
            -Math.min(contentWidth - triggerRect.width, rightOverflowAmount) +
            10;
        } else if (leftViewportOverflow && !rightViewportOverflow) {
          // If overflowing to the left but not to the right, adjust offsetX to move the overlay right
          offsetX =
            Math.min(
              contentWidth - triggerRect.width,
              Math.abs(triggerRect.left - contentWidth),
            ) - 10;
        } else if (
          (leftViewportOverflow && rightViewportOverflow) ||
          this.centerByX
        ) {
          // If overflowing on both sides, center the overlay on the trigger
          offsetX = -(contentWidth - triggerRect.width) / 2 - 10;
        }
      }

      // Define the new position strategy for the overlay.
      const newPosition: ConnectedPosition = {
        originX: 'start',
        originY: 'bottom',
        overlayX: isContentWider ? 'start' : 'center',
        overlayY: 'top',
        offsetX,
        offsetY: this.offsetY,
      };

      // Apply the new positioning strategy to the overlay.
      this._positionStrategy.withPositions([newPosition]);
      this._overlayRef!.updatePosition();
    }
  }

  /**
   * Closes the dropdown by detaching the overlay from the trigger element.
   * @private
   */
  private closeDropdown(): void {
    if (this._overlayRef) {
      this.triggerElement.nativeElement.style.zIndex = this._oldZIndex;
      this._oldZIndex = '0';
      this._overlayRef.detach();
      this._backdropClickSubscription?.unsubscribe();
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
    }
  }

  /**
   * Disposes the overlay and unsubscribes from the backdrop click event.
   * @private
   */
  private disposeOverlay(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
    if (this._backdropClickSubscription) {
      this._backdropClickSubscription.unsubscribe();
      this._backdropClickSubscription = null;
    }
  }

  /**
   * Recalculates the overlay position when the window is resized.
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    // Only try to recalculate the position if everything is properly initialized
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this.recalculateOverlayPosition();
    }
  }
}
