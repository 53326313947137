<div class="grid grid-cols-5 gap-6">
    <div class="col-span-5 lg:col-span-2">
      @if (authService.user$ | async; as user) {
          <div class="pb-8 segment">
            <div class="base-bg">
              <div class="relative flex items-center gap-4">
                @if (user.image) {
                  <img
                    class="h-10 w-10 rounded-full"
                    src="https://i.pravatar.cc/48"
                    alt="{{user.firstname + ' ' + user.lastname}}"/>
                } @else {
                  <div class="flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-gray-100">
                            <span class="text-2xl font-medium text-gray-400 group-hover:text-font-base">
                                {{ user.firstname.charAt(0) + user.lastname.charAt(0) }}
                            </span>
                  </div>
                }

                <div class="font-medium text-font-secondary">
                  <div class="hidden text-2xl font-bold text-black md:flex">{{ user.firstname + ' ' + user.lastname }}
                  </div>
                  <div class=""> {{ user.email }}</div>
                </div>
              </div>
            </div>
          </div>
        }
        <div class="pb-8 segment">
            <div class="mb-2 text-2xl font-bold title">{{'account.company_title' | translate}}</div>
            <div class="base-bg">
                <a class="flex w-full cursor-pointer border-b-2 py-4 text-lg last:border-0 hover:font-bold">Application mode</a>
                <a class="flex w-full cursor-pointer border-b-2 py-4 text-lg last:border-0 hover:font-bold">Personal data</a>
                <a class="flex w-full cursor-pointer border-b-2 py-4 text-lg last:border-0 hover:font-bold">Cookies</a>
            </div>
        </div>
    </div>
    <div class="col-span-5 lg:col-span-3">
        <div class="pb-8 segment">
            <div class="mb-2 text-2xl font-bold title">{{ 'account.profile_information' | translate }}</div>
            <div class="base-bg">

                <form [formGroup]="updateUserDataForm" (ngSubmit)="onSubmit()">
                    <div class="col-span-full mb-4">
                        <label for="first-name" class="text-lg font-medium text-font-secondary">{{ 'account.first_name' | translate }}</label>
                        <div class="mt-1">
                            <input type="text" name="first-name" id="first-name" formControlName="firstname" autocomplete="first-name" placeholder="{{ 'account.first_name_placeholder' | translate }}"  class="border-gray-300 text-left form-input-regular">
                        </div>
                    </div>
                    <div class="col-span-full mb-4">
                        <label for="last-name" class="text-lg font-medium text-font-secondary">{{ 'account.last_name' | translate }}</label>
                        <div class="mt-1">
                            <input type="text" name="last-name" id="last-name" formControlName="lastname" autocomplete="last-name" placeholder="{{ 'account.last_name_placeholder' | translate }}"  class="border-gray-300 text-left form-input-regular">
                        </div>
                    </div>
                  <div class="col-span-full mb-4">
                    <label for="phone" class="text-lg font-medium text-font-secondary">{{ 'account.phone_number' | translate }}</label>
                    <div class="mt-1">
                      <input type="text" name="phone" id="phone" formControlName="phone_number" autocomplete="phone" placeholder="{{ 'account.phone_number_placeholder' | translate }}"  class="border-gray-300 text-left form-input-regular">
                    </div>
                  </div>

                    <div class="pt-4 pb-10">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ 'account.pass_change_text' | translate }}</h2>
                        <!-- <p class="mt-1 text-sm leading-6 text-gray-600"></p> -->
                    </div>
                  <div class="col-span-full mb-4">
                    <label for="password_confirmation" class="text-lg font-medium text-font-secondary">{{ 'account.current_password' | translate }}</label>
                    <div class="mt-1">
                      <input type="password" name="password_confirmation" id="password_confirmation" formControlName="current_password" autocomplete="new-password" placeholder="{{ 'account.current_password_placeholder' | translate }}"  class="border-gray-300 text-left form-input-regular">
                    </div>
                  </div>

                    <div class="col-span-full mb-4">
                        <label for="password" class="text-lg font-medium text-font-secondary">{{ 'account.new_password' | translate }}</label>
                        <div class="mt-1">
                            <input type="password" name="password" id="password" formControlName="password" autocomplete="new-password" placeholder="{{ 'account.new_password_placeholder' | translate }}"  class="border-gray-300 text-left form-input-regular">
                        </div>
                    </div>


                    <div>
                        <fieldset>
                            <div class="mt-6 space-y-6">
                                <div class="relative flex gap-x-3">
                                    <div class="flex h-6 items-center">
                                        <input id="promo-agreement" name="promo-agreement" formControlName="gdpr_agreement" type="checkbox" class="h-6 w-6 rounded-md border-gray-300 text-brand-primary focus:ring-brand-text-brand-primary">
                                    </div>
                                    <div class="text-sm leading-6">
                                        <label for="promo-agreement" class="text-base font-medium text-font-base">Laborum nisi anim nisi et sit nostrud cillum consectetur esse. Commodo sint mollit proident id. Nostrud esse occaecat enim velit commodo magna.</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="mt-10 text-center">
                        <button type="submit" class="w-56 cursor-pointer rounded-full border-2 border-solid py-2 text-lg font-bold border-brand-primary text-brand-primary hover:bg-brand-primary hover:text-font-white focus-visible:bg-brand-primary focus-visible:text-font-white">{{ 'account.submit' | translate }}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
