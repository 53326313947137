import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

import {
  BaseModalComponent,
  ModalConfig,
} from '../components/utilities/modals/modal/modal.models';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _modals = new Map<string, ComponentRef<BaseModalComponent>>();
  private _viewContainerRef!: ViewContainerRef; // To be provided by the caller
  private _blockScroll: BlockScrollStrategy;

  constructor(private _overlay: Overlay) {
    this._blockScroll = this._overlay.scrollStrategies.block();
  }

  // Function to allow the modal host component to set the viewContainerRef
  public setViewContainerRef(vcr: ViewContainerRef): void {
    this._viewContainerRef = vcr;
  }

  public openModal<T extends BaseModalComponent>(config: ModalConfig<T>): void {
    if (!this._viewContainerRef) {
      console.error('ViewContainerRef not provided for ModalService');
      return;
    }

    const componentRef = this._viewContainerRef.createComponent(
      config.component,
    );

    const { id, ...contextWithoutId } = config.context || {};
    if (!id) {
      console.error('ID not provided in context for ModalService');
      return;
    }

    componentRef.instance.id = id;

    // Apply the rest of the context using the setContext method
    if (contextWithoutId) {
      componentRef.instance.setContext(contextWithoutId);
    }

    this._blockScroll.enable();

    this._modals.set(id, componentRef);
    componentRef.changeDetectorRef.detectChanges();
  }

  public closeModal(id: string): void {
    const componentRef = this._modals.get(id);
    if (componentRef) {
      componentRef.destroy();
      this._blockScroll.disable();

      this._modals.delete(id);
    }
  }
}
