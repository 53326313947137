<div class="flex w-full flex-col" >
  <div class="mb-2 text-2xl font-bold title">{{ 'problem_reporting.problem_reporting' | translate }}</div>
  <form class="flex flex-col items-center justify-start base-bg h-full laptop:min-h-[430px]"  [formGroup]="problemReportForm" (ngSubmit)="onSubmit()" >
    @switch (currentStep) {
      @case (1) {
        <div class="mb-6 flex flex-col justify-center text-black">
          <p class="text-center text-base">{{ 'problem_reporting.step' | translate }} 1.</p>
          <p class="text-center text-xl font-bold">{{ 'problem_reporting.upload_image' | translate }}</p>
        </div>
        <div class="mb-12 flex w-full items-center justify-center max-w-[520px]">
          <label for="image" class="flex aspect-auto max-h-80 w-full cursor-pointer flex-col items-center justify-center overflow-hidden border-[1px] border-font-secondary rounded-input hover:bg-gray-100">
            @if(problemReportForm.controls['image'].value) {
              <img [src]="problemReportForm.controls['image'].value" alt="Message describing problem" class="h-full w-full object-cover rounded-input">
            }
            @else {
              <div class="flex flex-col items-center justify-center pt-5 pb-6 text-lg">
                <aup-icon name="meter-detail" class="h-16 w-20 fill-font-secondary" ></aup-icon>
                <p class="mb-2 text-lg text-gray-500 dark:text-gray-400">
                  {{ 'problem_reporting.browse_text' | translate }}
                </p>
              </div>
            }
            <input #fileInput id="image" (change)="uploadFile($event)" type="file" accept="image/*, image/heic" class="hidden" />
          </label>
        </div>
        <div class="mt-auto flex flex-row items-center justify-center bg-white">
          <button routerLink="/" class="mr-2 modal-cancel-button">{{ 'problem_reporting.cancel' | translate }}</button>
          <button (click)="goToNextStep()" [disabled]="!problemReportForm.controls['image'].valid" type="submit" class="ml-2 modal-proceed-button">{{ 'problem_reporting.next' | translate }}</button>
        </div>
      }
      @case (2) {
        <div class="mb-6 flex flex-col justify-center text-black">
          <p class="text-center text-base">{{ 'problem_reporting.step' | translate }} 2.</p>
          <p class="text-center text-xl font-bold">{{ 'problem_reporting.select_meter' | translate }}</p>
        </div>
        <div class="mb-12 flex w-full items-center justify-center max-w-[520px]">
          @if (wmService.isLoading) {
            <div class="relative block mb-4 top-[-100px] w-full justify-start items-center">
              <aup-loader [isDark]="true"></aup-loader>
            </div>
          } @else {
            <aup-wm-dropdown
              classList="z-20 bg-white text-black text-md font-normal py-5 px-6 flex rounded-full justify-between items-center border border-[#AAAAAA80] w-[340px]"
              (wmClick)="selectWaterMeter($event)">
            </aup-wm-dropdown>
          }

        </div>
        <div class="mt-auto flex flex-row items-center justify-center bg-white">
          <button
            (click)="goToPreviousStep()"
            class="mr-2 modal-cancel-button">
            {{ 'problem_reporting.back' | translate }}
          </button>
          <button
            (click)="goToNextStep()"
            [disabled]="!problemReportForm.controls['waterMeter'].valid"
            type="submit" class="ml-2 modal-proceed-button">{{ 'problem_reporting.next' | translate }}
          </button>
        </div>
      }
      @case (3) {
        <div class="mb-6 flex flex-col justify-center text-black">
          <p class="text-center text-base">{{ 'problem_reporting.step' | translate }} 3.</p>
          <p class="text-center text-xl font-bold">{{ 'problem_reporting.description_label' | translate }}</p>
        </div>
        <div class="mb-12 flex w-full items-center justify-center max-w-[520px]">
          <textarea
            formControlName="description"
            rows="4"
            placeholder="{{ 'problem_reporting.description_placeholder' | translate }}"
            class="flex w-full p-[30px] rounded-input">
          </textarea>
        </div>
        <div class="mt-auto flex flex-row items-center justify-center bg-white">
          <button (click)="goToPreviousStep()" class="mr-2 modal-cancel-button">{{ 'problem_reporting.back' | translate }}</button>
          <button type="submit" [disabled]="!problemReportForm.controls['description'].valid || isLoading"  class="ml-2 modal-proceed-button">{{ 'problem_reporting.submit' | translate }}</button>
        </div>
      }
    }
  </form>
</div>
