<!--<aup-loader *ngIf="loading"></aup-loader>-->

<div class="overflow-x-hidden overflow-y-auto bg-[radial-gradient(circle,_#0B42AC,_#062156)] flex flex-col justify-start items-center text-font-white">
  <div class="mt-10 mb-10 w-1/2 max-w-[250px] sm:mt-16">
    <img src="assets/images/logo-light.svg" alt="vodomer logo">
  </div>
  @if (!formSubmitted) {
  <div class="flex flex-col justify-center text-center sm:max-w-[530px]">
    <h1 class="mb-6 text-xl font-bold">{{ 'register.register_text' | translate }}</h1>
    <p class="mb-8 whitespace-pre-line">
      {{ 'register.register_subtext' | translate }}
    </p>
    <div class="flex flex-col justify-center">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="w-full text-font-base">
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="firstName">{{ 'register.first_name' | translate }}</label>
          <input class="form-input-regular" type="text"  id="firstName" formControlName="firstName" autocomplete="given-name" placeholder="{{ 'register.first_name_placeholder' | translate }}" />
          <aup-form-field-error
            [controls]="[registerForm.controls['firstName']]"
            [errors]="{
              'required': ('register.first_name' | translate) + ' ' + ('register.error.is_required' | translate),
              'invalid': ('register.first_name' | translate) + ('register.error.is_invalid' | translate)
            }">
          </aup-form-field-error>
        </div>
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="lastName">{{ 'register.last_name' | translate }}</label>
          <input class="form-input-regular" type="text" id="lastName" formControlName="lastName" autocomplete="family-name" placeholder="{{'register.last_name_placeholder' | translate}}" />
          <aup-form-field-error [controls]="[registerForm.controls['lastName']]" [errors]="{
            'required': ('register.last_name' | translate) + ' ' + ('register.error.is_required' | translate),
            'invalid': ('register.last_name' | translate) + ('register.error.is_invalid' | translate)
          }">
          </aup-form-field-error>
        </div>
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="phone">{{ 'register.phone' | translate }}</label>
          <input class="form-input-regular" type="tel" id="phone" formControlName="phone" autocomplete="tel" placeholder="{{'register.phone_placeholder' | translate}}" />
          <aup-form-field-error [controls]="[registerForm.controls['phone']]" [errors]="{
            'required': ('register.phone' | translate) + ' ' + ('register.error.is_required' | translate),
            'invalid': ('register.phone' | translate) + ('register.error.is_invalid' | translate)
          }">
          </aup-form-field-error>
        </div>
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="email">{{ 'register.email' | translate }}</label>
          <input class="form-input-regular" type="email" id="email" formControlName="email" autocomplete="email" placeholder="{{'register.email_placeholder' | translate}}" />
          <aup-form-field-error [controls]="[registerForm.controls['email']]" [errors]="{
            'required': ('register.email' | translate) + ' ' + ('register.error.is_required' | translate),
            'invalid': ('register.email' | translate) + ('register.error.is_invalid' | translate)
          }">
          </aup-form-field-error>
        </div>
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="password">{{ 'register.password' | translate }}</label>
          <input class="form-input-regular" type="password" id="password" formControlName="password" autocomplete="off" placeholder="{{'register.password_placeholder' |translate}}" />
          <aup-form-field-error [controls]="[registerForm.controls['password']]" [errors]="{
            'passwordStrengthError': registerForm.get('password')?.getError('passwordStrengthError'),
            'required': ('register.password' | translate) + ' ' + ('register.error.is_required' | translate),
            'minlength': ('register.error.minlength' | translate: {length: registerForm.get('password')?.getError('minlength')?.requiredLength})
          }">
          </aup-form-field-error>
        </div>
        <div class="mx-4 flex flex-col items-center justify-center">
          <label class="text-base text-font-white mb-2.5" for="confirmPassword">{{ 'register.password_reenter' | translate }}</label>
          <input class="form-input-regular" type="password" id="confirmPassword" formControlName="confirmPassword" autocomplete="off" placeholder="{{'register.password_reenter_placeholder' | translate}}" />
          <aup-form-field-error [controls]="[registerForm, registerForm.controls['confirmPassword']]" [errors]="{
            'required': ('register.error.password_confirm' | translate),
            'passwordsMismatch': ('register.error.password_mismatch' | translate)
          }">
          </aup-form-field-error>
        </div>
        <div class="flex flex-col justify-start px-4 sm:px-0">
          <div class="flex items-center justify-start">
            <input type="checkbox" id="termsAndConditions" class="custom-checkbox" formControlName="termsAndConditions" />
            <label class="ml-2 text-sm text-white" for="termsAndConditions">{{ 'register.terms_text' | translate }} *</label>
          </div>
          <div class="flex justify-start">
            <aup-form-field-error [controls]="[registerForm.controls['termsAndConditions']]" [errors]="{
              'required': ('register.error.is_required_to_continue' | translate)
            }">
            </aup-form-field-error>
          </div>
        </div>
        <div class="mb-10 flex items-start justify-start px-4 sm:px-0">
          <input type="checkbox" id="promoAgreement" class="custom-checkbox" formControlName="promoAgreement" />
          <label class="ml-2 text-justify text-sm text-white" for="promoAgreement">{{ 'register.gdpr_text' | translate }}</label>
        </div>
        <div class="px-4 text-left text-sm text-white sm:px-0">
          <p class="mb-4">
            {{ 'register.tos.part_1' | translate}} <a href="#" class="font-bold">{{ 'register.tos.link_os_podatke' | translate }}</a> {{'register.tos.part_2' | translate}} <a href="#" class="font-bold">{{'register.tos.link_cookies' | translate}}</a>
          </p>
          <p class="">
            <a href="#" class="font-bold">{{'register.tos.form_link' | translate}}</a> {{'register.tos.form_text' | translate}}
          </p>
        </div>
        <div class="my-8 flex justify-center px-4 sm:px-0">
          <button class="w-full outlined-reverse-button sm:w-fit">{{ 'register.create_account' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
  }
  </div>

