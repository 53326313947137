import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AuthService } from '../../../services/auth.service';
import { FormFieldErrorComponent } from '../../utilities/form-field-error/form-field-error.component';
import { LoaderComponent } from '../../utilities/loader/loader.component';
import { passwordsMatchValidator } from '../../utilities/validators/custom-form-validators';

@Component({
  selector: 'aup-reset-password',
  standalone: true,
  imports: [
    FormFieldErrorComponent,
    LoaderComponent,
    ReactiveFormsModule,
    TranslateModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent implements OnInit {
  token: string | null = null;
  loading: boolean = false;
  requestSent: boolean = false;
  resetPasswordForm: FormGroup = new FormGroup(
    {
      password: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl<string>('', [
        Validators.required,
        passwordsMatchValidator,
      ]),
      token: new FormControl<string>(''),
    },
    { validators: passwordsMatchValidator },
  );
  initResetPasswordForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
  });
  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    const tokenFromPath = this.activatedRoute.snapshot.paramMap.get('token');
    this.resetPasswordForm.controls['token'].setValue(tokenFromPath);
    this.token = tokenFromPath;
  }

  onNewPasswordSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.loading = true;

      this.authService.resetPassword(this.resetPasswordForm.value).subscribe({
        next: (_) => {
          this.loading = false;
        },
        error: (e) => {
          console.error('Registration error:', e);
          this.loading = false;
        },
      });
    } else {
      this.resetPasswordForm.markAllAsTouched();
    }
  }

  onInitResetPassword() {
    if (this.initResetPasswordForm.valid) {
      this.loading = true;

      this.authService
        .initPasswordReset(this.initResetPasswordForm.value)
        .subscribe({
          next: (_) => {
            this.loading = false;
            if (_.status) {
              this.requestSent = true;
            }
          },
          error: (e) => {
            console.error('Registration error:', e);
            this.loading = false;
          },
        });
    } else {
      this.initResetPasswordForm.markAllAsTouched();
    }
  }
}
