import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aup-modal-button',
  standalone: true,
  imports: [],
  templateUrl: './modal-button.component.html',
  styleUrl: './modal-button.component.scss',
})
export class ModalButtonComponent {
  @Input() text!: string;
  @Input() type: 'proceed' | 'cancel' = 'proceed';
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  onClick(e: MouseEvent) {
    this.clicked.emit(e);
  }
}
