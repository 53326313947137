import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ModalService } from '../../../../../services/modal.service';
import { WaterMeterService } from '../../../../../services/water-meter.service';
import { ModalButtonComponent } from '../../../buttons/modal-button/modal-button.component';
import { FormFieldErrorComponent } from '../../../form-field-error/form-field-error.component';
import { IconComponent } from '../../../icon/icon.component';
import { ModalComponent } from '../../modal/modal.component';
import { BaseModalComponent } from '../../modal/modal.models';

@Component({
  selector: 'aup-send-wm-measurements-modal',
  standalone: true,
  imports: [
    ModalComponent,
    FormFieldErrorComponent,
    IconComponent,
    ReactiveFormsModule,
    FormsModule,
    ModalButtonComponent,
    TranslateModule,
  ],
  templateUrl: './send-wm-measurements-modal.component.html',
  styleUrl: './send-wm-measurements-modal.component.scss',
})
export class SendWmMeasurementsModalComponent implements BaseModalComponent {
  @Input() id!: string;
  sendMeasurementsForm: FormGroup = new FormGroup({
    image: new FormControl<string>('', [Validators.required]),
    counter: new FormControl<string | null>(null, [Validators.required]),
  });
  loading: boolean = false;
  currentStep: 1 | 2 = 1;
  private _subscriptions: Subscription = new Subscription();
  constructor(
    protected modal: ModalService,
    private _toastr: ToastrService,
    private _wmService: WaterMeterService,
  ) {}

  setContext(context: unknown): void {
    Object.assign(this, context);
  }

  onSubmit() {
    if (this.sendMeasurementsForm.valid) {
      this.loading = true;
      this._subscriptions.add(
        this._wmService
          .sendWmMeasurements(this.sendMeasurementsForm.value)
          .subscribe({
            next: (_) => {
              this.loading = false;
              this.modal.closeModal(this.id);
            },
            error: (e) => {
              console.error('Send measurements error:', e);
              this.loading = false;
            },
          }),
      );
    }
  }

  public goToNextStep() {
    this.currentStep = 2;
  }

  public goToPreviousStep() {
    this.currentStep = 1;
  }

  public closeModal() {
    this.modal.closeModal(this.id);
  }

  async uploadFile(event: Event): Promise<void> {
    const input = event.target as HTMLInputElement;

    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    try {
      const base64 = await this.convertToBase64(file);
      this.sendMeasurementsForm.patchValue({ image: base64 });
      this.sendMeasurementsForm.get('image')?.updateValueAndValidity();
    } catch (error) {
      this._toastr.error('An error occurred while uploading the file', 'Error');
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}
