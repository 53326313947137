import { Component, Input } from '@angular/core';

@Component({
  selector: 'aup-icon',
  standalone: true,
  imports: [],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  @Input() name = '';
  @Input() class = '';
}
