import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  // Check and attach the token
  const attachToken = (request: HttpRequest<unknown>): HttpRequest<unknown> => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !request.url.includes('/users/refresh')) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return request;
  };

  // Modify request to attach the token
  const authReq = attachToken(req);

  // Pass the request to the next handler, along with any response handling logic
  return next(authReq);
};
