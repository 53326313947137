<aup-modal [id]="id">
  <div class="flex flex-wrap items-center justify-between modal-header bg-brand-primary px-[16px] py-[25px] sm:rounded-t-[27px] sm:px-[33px]">
    <h2 class="text-xl font-bold text-white">{{'modals.monitoring_wm.title' | translate}}</h2>
    <svg (click)="modal.closeModal(id)" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </div>
  <div class="inline-block h-full w-full items-center justify-center bg-white px-7 pb-6 text-lg text-gray-500 modal-body rounded-b-[27px] sm:flex sm:flex-col">
    <p class="mt-6 mb-12">
      {{'modals.monitoring_wm.text' | translate}}
    </p>
    <div class="flex flex-row items-center justify-center">
      <form [formGroup]="enableMonitoringForm" class="flex w-full flex-row justify-center" >
        <aup-icon name="faucet-closed" [ngClass]="{'w-24 h-24': true, 'fill-font-secondary': toggleState | async, 'fill-brand-primary ': (toggleState | async) === false}"></aup-icon>
        <label for="monitor" class="mx-6 mb-5 inline-flex cursor-pointer items-center">
          <input (change)="checkChange($event)" formControlName="monitor" id="monitor" type="checkbox" class="sr-only peer">
          <div class="relative w-[75px] h-8 bg-brand-primary peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-[43px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[3px] after:start-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-[26px] after:h-[26px] after:transition-all peer-checked:bg-brand-red"></div>
        </label>
          <aup-icon name="faucet-opened" [ngClass]="{'w-24 h-24': true, 'fill-brand-red': toggleState | async, 'fill-font-secondary': (toggleState | async) === false}"></aup-icon>
      </form>
    </div>
  </div>
</aup-modal>

