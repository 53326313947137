import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { Toast } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[toast-notification-component]',
  standalone: true,
  imports: [TranslateModule],
  styles: [
    `
      :host {
        position: relative;
        overflow: hidden;
        //margin: 30px 15PX  15px  15px !important;
        margin-bottom: 20px !important;
        padding: 10px 10px 10px 10px;
        width: 100%;
        max-width: 300px;
        border-radius: 16px !important;
        color: #ffffff;
        pointer-events: all;
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div class="row" [style.display]="state.value !== 'inactive' ? '' : 'none'">
      <div class="col-9">
        @if (title) {
          <div [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
          </div>
        }
        @if (message && options.enableHtml) {
          <div
            role="alert"
            [class]="options.messageClass"
            [innerHTML]="message"
          ></div>
        }
        @if (message && !options.enableHtml) {
          <div
            role="alert"
            [class]="options.messageClass"
            [attr.aria-label]="message"
          >
            {{ message }}
          </div>
        }
      </div>
      <div class="text-right col-3">
        @if (options.closeButton) {
          <a
            (click)="remove()"
            (keyup.enter)="remove()"
            class="btn btn-sm"
            tabindex="0"
          >
            {{ 'toast.close' | translate }}
          </a>
        }
      </div>
    </div>
    @if (options.progressBar) {
      <div>
        <div class="toast-progress" [style.width]="width + '%'"></div>
      </div>
    }
  `,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ]),
        ),
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ]),
        ),
      ),
    ]),
  ],
})
export class ToastNotificationComponent extends Toast {}
