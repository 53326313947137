<aup-modal [id]="id">
  <div class="flex flex-wrap items-center justify-between modal-header bg-brand-primary px-[16px] py-[25px] sm:rounded-t-[27px] sm:px-[33px]">
    <h2 class="text-xl font-bold text-white">{{'modals.rename_wm.title' | translate}}</h2>
    <svg (click)="modal.closeModal(id)" class="h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  </div>
  <div class="inline-block h-full w-full items-center justify-center bg-white px-7 pb-6 text-lg text-gray-500 modal-body rounded-b-[27px] sm:flex sm:flex-col">
    <form [formGroup]="renameWaterMeterForm" (ngSubmit)="onSubmit()" class="w-full">
      <div class="flex w-full flex-col items-center justify-center">
        <label class="mt-6 mb-4 font-bold uppercase text-font-secondary" for="wmAlias">{{'modals.rename_wm.name' | translate}}</label>
        <input type="text" class="mb-4 text-black modal-input" id="wmAlias" formControlName="wmAlias" autocomplete="off" placeholder="{{'modals.rename_wm.name_placeholder' | translate}}">
        <!--        <aup-form-field-error [removeInnerSpacings]="true" [controls]="[newWaterMeterForm.controls['contractNumber']]" [errors]="{'required': 'Metering site number is required'}"></aup-form-field-error>-->
        <button (click)="removeWmAlias($event)" type="reset" class="mb-8 text-lg font-normal text-brand-red">{{'modals.rename_wm.remove_button' | translate}}</button>
      </div>
      <div class="flex flex-row items-center justify-center bg-white p-2">
        <aup-modal-button text="{{'modals.buttons.cancel' | translate}}" type="cancel" (clicked)="modal.closeModal(id)"></aup-modal-button>
        <aup-modal-button [disabled]="loading" text="{{'modals.buttons.save' | translate}}" type="proceed"></aup-modal-button>
      </div>
    </form>
  </div>
</aup-modal>

