import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ModalService } from '../../../../../services/modal.service';
import { WaterMeterService } from '../../../../../services/water-meter.service';
import { ModalButtonComponent } from '../../../buttons/modal-button/modal-button.component';
import { IconComponent } from '../../../icon/icon.component';
import { ModalComponent } from '../../modal/modal.component';
import { BaseModalComponent } from '../../modal/modal.models';

@Component({
  selector: 'aup-mobile-alarm-modal',
  standalone: true,
  imports: [
    ModalButtonComponent,
    ModalComponent,
    ReactiveFormsModule,
    AsyncPipe,
    TranslateModule,
    IconComponent,
  ],
  templateUrl: './mobile-alarm-modal.component.html',
  styleUrl: './mobile-alarm-modal.component.scss',
})
export class MobileAlarmModalComponent implements BaseModalComponent {
  @Input() id!: string;
  constructor(
    protected wmService: WaterMeterService,
    protected modal: ModalService,
  ) {}

  setContext(context: unknown): void {
    Object.assign(this, context);
  }
}
