import { AsyncPipe, DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AddWaterMeterComponent } from './add-water-meter/add-water-meter.component';
import { FlowbiteMarkDirective } from '../../../directives/flowbite-mark.directive';
import { NumberMutatorPipe } from '../../../pipes/number-mutator.pipe';
import { ModalService } from '../../../services/modal.service';
import { WaterMeterService } from '../../../services/water-meter.service';
import { ChartComponent } from '../../utilities/chart/chart.component';
import { DropdownComponent } from '../../utilities/dropdowns/dropdown/dropdown.component';
import { WmDropdownComponent } from '../../utilities/dropdowns/wm-dropdown/wm-dropdown.component';
import { IconComponent } from '../../utilities/icon/icon.component';
// components
import { LoaderComponent } from '../../utilities/loader/loader.component';
import { EnableWmMonitoringModalComponent } from '../../utilities/modals/custom-modals/enable-wm-monitoring-modal/enable-wm-monitoring-modal.component';
import { RenameWatermeterModalComponent } from '../../utilities/modals/custom-modals/rename-watermeter-modal/rename-watermeter-modal.component';
import { SendWmMeasurementsModalComponent } from '../../utilities/modals/custom-modals/send-wm-measurements-modal/send-wm-measurements-modal.component';
import { BaseModalComponent } from '../../utilities/modals/modal/modal.models';
import {
  modalComponentMap,
  ModalComponentMap,
} from '../../utilities/modals/modal/wm-modals.map';
import { SliderSlideDirective } from '../../utilities/sliders/slider/slide.directive';
import { SliderComponent } from '../../utilities/sliders/slider/slider.component';

@Component({
  selector: 'aup-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [
    AddWaterMeterComponent,
    ChartComponent,
    AddWaterMeterComponent,
    IconComponent,
    TranslateModule,
    SendWmMeasurementsModalComponent,
    RenameWatermeterModalComponent,
    EnableWmMonitoringModalComponent,
    RouterLink,
    AsyncPipe,
    LoaderComponent,
    DecimalPipe,
    FlowbiteMarkDirective,
    NgClass,
    WmDropdownComponent,
    DropdownComponent,
    SliderComponent,
    SliderSlideDirective,
    DatePipe,
    NumberMutatorPipe,
  ],
})
export class DashboardComponent {
  constructor(
    private _modal: ModalService,
    protected wmService: WaterMeterService,
  ) {}

  openWaterMeterModal(modalType: keyof ModalComponentMap) {
    const modalComponent = modalComponentMap[modalType] || null;

    if (modalComponent) {
      this._modal.openModal({
        component: modalComponent,
        context: {
          id: modalType,
          currentAlias: 'currentAlias',
        } as Partial<BaseModalComponent>,
      });
    }
  }

  currentWmHasAlarms(alarmsCount: number | null) {
    return alarmsCount && alarmsCount > 0;
  }
}
