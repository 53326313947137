import { Component, Input } from '@angular/core';

import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'aup-modal',
  standalone: true,
  template: `
    <!-- Modal backdrop -->
    <div
      tabindex="0"
      (keydown.enter)="closeModal()"
      (click)="closeModal()"
      class="fixed inset-0 z-40 h-full w-full cursor-pointer overflow-y-auto bg-gray-600 bg-opacity-50"
      id="my-modal"
    ></div>
    <!-- Modal content -->
    <ng-container>
      <div
        class="fixed top-0 left-0 z-50 h-full w-full sm:rounded-[27px] sm:w-[580px] sm:top-[18%] sm:left-1/2 sm:h-auto sm:-translate-x-1/2 sm:transform"
      >
        <ng-content></ng-content>
      </div>
    </ng-container>
  `,
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  @Input() id: string = '';

  constructor(private _modalService: ModalService) {}

  closeModal() {
    this._modalService.closeModal(this.id);
  }
}
