<header class="flex bg-white drop-shadow-lg min-h-16 tablet:min-h-24" xmlns="http://www.w3.org/1999/html">
  @if (authService.user$ | async; as user) {
    <a routerLink="/" class="flex cursor-pointer flex-col justify-center px-3 tablet:px-8 min-w-36 md:min-w-64">
        <img class="w-full logo max-w-36" [src]="user.settings.company_logo" [alt]="user.settings.company_name + ' logo'">
    </a>
    <div class="flex hidden w-1/3 items-center justify-start px-4 md:flex">{{user.settings.company_name}}</div>
    <div class="relative flex w-2/3 items-center justify-end px-6">
      <!-- Alarms dropdown     -->
      @if (wmService.getWaterMetersWithAlarms() | async; as wms) {
        <aup-dropdown [centerByX]="true" [offsetY]="calculatedDdOffset">
          <button
            dropdown-trigger
            class="mr-4 flex cursor-pointer items-center text-brand-red">
            <aup-icon class="h-8 w-8 fill-brand-red" [name]="'temporary_error'"></aup-icon>
            <span class="ml-1 hidden uppercase md:flex">
                  {{ 'nav.header.warning' | translate }}
              </span>
          </button>
          <div dropdown-content id="warningDropdown" class="w-64 bg-white shadow divide-y divide-gray-100 rounded-b-plate border-t-[5px] border-brand-red">
            <ul class="flex w-full flex-col py-2 text-sm text-gray-700" aria-labelledby="dropdownHoverButton">
              @for(wm of wms; track wm.device_sn; let i = $index) {
                <li class="flex w-full">
                  <a
                    [tabindex]="i"
                    (keydown.enter)="wmService.setCurrentWaterMeter(wm.device_sn, true)"
                    (click)="wmService.setCurrentWaterMeter(wm.device_sn, true)"
                    class="flex w-full cursor-pointer flex-col px-4 py-2">
                    <div class="flex h-full w-full flex-row items-center justify-between">
                      <div>
                        <p class="text-black">
                          {{'nav.header.check_problem' | translate}}
                        </p>
                        <span class="text-lg font-bold text-brand-red">{{wm.device_sn}}</span>
                      </div>
                      <div>
                        <svg class="h-4 w-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path stroke="#E30404" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"> </path> </svg>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start">
                      @for (alarm of wm.last_log?.alarms; track alarm.id) {
                        <div class="flex flex-row items-center justify-start">
                          <aup-icon class="h-8 w-8 fill-brand-red" [name]="alarm.name"></aup-icon>
                          <span class="text-base text-black">{{'alarm.' + alarm.name | translate}}</span>
                        </div>
                        <p class="ml-8">
                          {{wm.last_log?.readout_time | date: 'dd.MM.yyyy HH:mm:ss'}}
                        </p>
                      }
                    </div>
                  </a>
                </li>
              }
            </ul>
          </div>
        </aup-dropdown>
      }

      <!-- language selector -->
      <div class="mr-4">
        <aup-dropdown [offsetY]="14">
          <button dropdown-trigger class="flex w-full items-center justify-between rounded px-3 py-2 uppercase text-gray-400 hover:text-font-base">
            {{ translate.currentLang }}
            <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>
          <div dropdown-content class="rounded-lg bg-white shadow divide-y">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLargeButton">
              <li>
                <a tabindex="0" (keydown.enter)="changeLocalization('en')" (click)="changeLocalization('en')" class="block cursor-pointer px-4 py-2 uppercase hover:bg-gray-100">
                  {{ 'language.abbreviation.en' | translate }}
                </a>
              </li>
              <li>
                <a tabindex="1" (keydown.enter)="changeLocalization('sl')"  (click)="changeLocalization('sl')" class="block cursor-pointer px-4 py-2 uppercase hover:bg-gray-100">
                  {{ 'language.abbreviation.sl' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </aup-dropdown>
      </div>

      <!-- profile -->
      <div>
        <aup-dropdown [offsetY]="14">
          <div
            type="button"
            class="flex cursor-pointer items-center group"
            dropdown-trigger
          >

            @if (user.image) {
              <img
                class="h-10 w-10 rounded-full"
                src="https://i.pravatar.cc/48"
                alt="{{user.firstname + ' ' + user.lastname}}" />
            }
            @else {
              <div class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-100">
                      <span class="font-medium text-gray-400 group-hover:text-font-base">
                          {{user.firstname.charAt(0) + user.lastname.charAt(0) }}
                      </span>
              </div>
            }

            <div class="ml-2 font-medium text-gray-400 group-hover:text-font-base" >
              <div class="hidden md:flex">{{user.firstname + ' ' + user.lastname}}</div>
            </div>
          </div>
          <div dropdown-content id="userDropdown" class="w-56 rounded-lg bg-white shadow divide-y divide-gray-100">
            <div class="cursor-pointer px-4 py-3 text-sm text-gray-900 dark:text-white">
              <a routerLink="account">
                <div class="truncate font-medium">{{ user.email }}</div>
              </a>
            </div>
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
              <li>
                <a
                  [tabindex]="7"
                  (keydown.enter)="logout()"
                  (click)="logout()"
                  class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{'nav.header.logout' | translate}}</a>
              </li>
            </ul>
          </div>
        </aup-dropdown>
      </div>
    </div>
  }
</header>
