import { Component, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { WaterMeterService } from '../../../../services/water-meter.service';
import { FormFieldErrorComponent } from '../../../utilities/form-field-error/form-field-error.component';
import { IconComponent } from '../../../utilities/icon/icon.component';
import { LoaderComponent } from '../../../utilities/loader/loader.component';

@Component({
  selector: 'aup-add-water-meter',
  standalone: true,
  imports: [
    IconComponent,
    FormsModule,
    ReactiveFormsModule,
    FormFieldErrorComponent,
    LoaderComponent,
    TranslateModule,
  ],
  templateUrl: './add-water-meter.component.html',
  styleUrl: './add-water-meter.component.scss',
})
export class AddWaterMeterComponent implements OnDestroy {
  newWaterMeterForm: FormGroup = new FormGroup({
    contractNumber: new FormControl<string>('', [Validators.required]),
    siteNumber: new FormControl<string | null>(null, [Validators.required]),
    isOwner: new FormControl<boolean>(false),
  });
  loading: boolean = false;
  private _subscriptions: Subscription = new Subscription();
  constructor(private _wmService: WaterMeterService) {}

  onSubmit() {
    if (this.newWaterMeterForm.valid) {
      this.loading = true;
      this._subscriptions.add(
        this._wmService.addWaterMeter(this.newWaterMeterForm.value).subscribe({
          next: (_) => {
            this.loading = false;
          },
          error: (e) => {
            console.error('Add water meter error:', e);
            this.loading = false;
          },
        }),
      );
    } else {
      this.newWaterMeterForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
