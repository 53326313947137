import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/IUtil.types';
import {
  IMeterInfo,
  INewWaterMeterForm,
  IWaterMeter,
  IWmGraphData,
} from '../models/IWaterMeters.types';
import {TranslatePipe} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class WaterMeterService implements OnDestroy {
  private _wmsSubject: BehaviorSubject<IWaterMeter[] | null>;
  public wms$: Observable<IWaterMeter[] | null>;

  private _currentWmSubject: BehaviorSubject<IWaterMeter | null>;
  public currentWm$: Observable<IWaterMeter | null>;

  private _wmsInfoSubject: BehaviorSubject<IMeterInfo[] | null>;
  public wmsInfo$: Observable<IMeterInfo[] | null>;

  private _subscriptions: Subscription = new Subscription();

  public isLoading: boolean = true;
  public areWmLoading: boolean = true;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _toastr: ToastrService,
    private _route: ActivatedRoute,
    private _translate: TranslatePipe,
  ) {
    this._wmsSubject = new BehaviorSubject<IWaterMeter[] | null>(null);
    this.wms$ = this._wmsSubject.asObservable();

    this._currentWmSubject = new BehaviorSubject<IWaterMeter | null>(null);
    this.currentWm$ = this._currentWmSubject.asObservable();

    this._wmsInfoSubject = new BehaviorSubject<IMeterInfo[] | null>(null);
    this.wmsInfo$ = this._wmsInfoSubject.asObservable();
  }

  /**
   * Add water meter
   * @param form
   */
  addWaterMeter(form: INewWaterMeterForm) {
    return this._http
      .post<
        ApiResponse<string>
      >(`${environment.apiUrl}/meters/add`, { billing_number: form.contractNumber, site_id: form.siteNumber })
      .pipe(
        tap((response) => {
          if (response.status) {
            this._toastr.success(this._translate.transform('notifications.success.add'), this._translate.transform('notifications.success.title'));
            this.getWaterMeters().subscribe();
          }
        }),
        catchError((e) => {
          console.log('Error:', e);
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.add'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
      );
  }

  getWaterMeters(): Observable<ApiResponse<IWaterMeter[]>> {
    this.areWmLoading = true;
    this.isLoading = true;

    return this._http
      .get<ApiResponse<IWaterMeter[]>>(`${environment.apiUrl}/meters/get`)
      .pipe(
        tap((response) => {
          if ('data' in response && response.data.length > 0) {
            this._wmsSubject.next(this.initedWaterMeters(response.data));
            const defaultWmId =
              this._route.snapshot.queryParams['watermeter_id'];
            this.setCurrentWaterMeter(
              defaultWmId || response.data[0].device_sn,
            );
          } else this._wmsSubject.next(null);
          this.areWmLoading = false;
          this.isLoading = false;
        }),
        catchError((e: HttpErrorResponse) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          this.isLoading = false;
          this.areWmLoading = false;

          return throwError(() => new Error(e.message || 'Unknown error'));
        }),
      );
  }

  /**
   * Get meter info
   */
  getMeterInfo() {
    this.isLoading = true;
    return this._http
      .get<ApiResponse<IMeterInfo[]>>(`${environment.apiUrl}/meters/info`)
      .pipe(
        tap((response) => {
          if ('data' in response && response.data.length > 0) {
            this._wmsInfoSubject.next(response.data);
          }
          this.isLoading = false;
        }),
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
      );
  }

  /**
   * Rename the current water meter
   * @param form
   */
  renameWatermeter(form: { id: string; wmAlias: string }) {
    return this._http
      .patch<
        ApiResponse<string>
      >(`${environment.apiUrl}/meters/alias`, { meter_id: this._currentWmSubject.getValue()?.meter_id, alias: form.wmAlias })
      .pipe(
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
        tap(async (res) => {
          if (res.status) {
            this._toastr.success(this._translate.transform('notifications.success.generic'), this._translate.transform('notifications.success.title'));
            await lastValueFrom(this.getWaterMeters());
          }
        }),
      );
  }

  /**
   * Send water meter measurements for current water meter
   * @param form
   */
  sendWmMeasurements(form: { image: string; counter: string }) {
    return this._http
      .post<
        ApiResponse<string>
      >(`${environment.apiUrl}/meters/measurement`, { meter_id: this._currentWmSubject.getValue()?.meter_id, image: form.image, counter: form.counter })
      .pipe(
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
        tap((res) => {
          if (res.status) {
            this._toastr.success(
              this._translate.transform('notifications.success.measurement-sent'),
              this._translate.transform('notifications.success.title')
            );
          }
        }),
      );
  }

  /**
   * Enable or disable monitoring for the current water meter
   * @param form
   */
  enableMonitoring(form: { monitor: boolean }) {
    return this._http
      .patch<
        ApiResponse<string>
      >(`${environment.apiUrl}/meters/monitoring`, { meter_id: this._currentWmSubject.getValue()?.meter_id, monitor: form.monitor })
      .pipe(
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
        tap(async (res) => {
          if (res.status) {
            this._toastr.success(this._translate.transform('notifications.success.generic'), this._translate.transform('notifications.success.title'));
            await lastValueFrom(this.getWaterMeters());
          }
        }),
      );
  }

  /**
   * Initializes the water meters (sets the "current" property)
   * @param wms
   * @returns {Array<IWaterMeter>}
   */
  private initedWaterMeters(wms: Array<IWaterMeter>): Array<IWaterMeter> {
    return wms.map((wm, index) => ({
      ...wm,
      current: index === 0,
    }));
  }

  /**
   * Sets the current water meter
   * @param wm_id
   * @param withRedirect
   */
  public setCurrentWaterMeter(
    wm_id: string,
    withRedirect: boolean = false,
  ): void {
    const currentWmUpdateSub = this.currentWm$
      .pipe(
        take(1),
        switchMap((_) => {
          return this.wms$.pipe(
            take(1),
            map((waterMeters) => {
              if (!waterMeters) return null;
              let newCurrentWm: IWaterMeter | null = null;
              const updatedWaterMeters = waterMeters.map((wm) => {
                const isCurrent = wm.device_sn === wm_id;
                if (isCurrent) newCurrentWm = wm;
                return {
                  ...wm,
                  current: isCurrent,
                };
              });
              this._currentWmSubject.next(newCurrentWm);
              return updatedWaterMeters;
            }),
          );
        }),
      )
      .subscribe((updatedWaterMeters) => {
        if (updatedWaterMeters !== null) {
          this._wmsSubject.next(updatedWaterMeters);
          if (withRedirect) this._router.navigate(['/']);
        }
      });
    this._subscriptions.add(currentWmUpdateSub);
  }

  /**
   * getChartDataForCurrentWaterMeter()
   */
  getChartDataForCurrentWaterMeter(): Observable<
    ApiResponse<Omit<IWmGraphData, 'hourly'>>
  > {
    return this._http
      .get<
        ApiResponse<IWmGraphData>
      >(`${environment.apiUrl}/meters/graph?meter_id=${this._currentWmSubject.getValue()?.meter_id}`)
      .pipe(
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
      );
  }

  getHourlyDataForCurrentWaterMeter(
    date: string,
  ): Observable<ApiResponse<Pick<IWmGraphData, 'hourly'>>> {
    return this._http
      .get<
        ApiResponse<IWmGraphData>
      >(`${environment.apiUrl}/meters/graph/daily?meter_id=${this._currentWmSubject.getValue()?.meter_id}&date=${date}`)
      .pipe(
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.generic'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
      );
  }

  /**
   * Get the current water meter as Observable
   * @returns {Observable<IWaterMeter | null>}
   */
  public getCurrentWaterMeter(): Observable<IWaterMeter | null> {
    return this.wms$.pipe(
      switchMap((waterMeters) =>
        waterMeters === null
          ? of(null)
          : of(waterMeters.find((wm) => wm.current) || null),
      ),
    );
  }

  get currentWmValue(): IWaterMeter | null {
    return this._currentWmSubject.getValue();
  }

  getWaterMetersWithAlarms(): Observable<IWaterMeter[] | null> {
    return this.wms$.pipe(
      map((waterMeters) => {
        if (!waterMeters) return null;
        const problematicWms = waterMeters.filter(
          (wm) => wm.last_log && wm.last_log.alarms.length > 0,
        );
        return problematicWms.length > 0 ? problematicWms : null;
      }),
    );
  }

  getWaterMeterBySn(sn: string): Observable<IWaterMeter | null> {
    return this.wms$.pipe(
      map((waterMeters) => {
        if (!waterMeters) return null;
        return waterMeters.find((wm) => wm.device_sn === sn) || null;
      }),
    );
  }

  isMultipleWm(): Observable<boolean> {
    return this.wms$.pipe(
      map((waterMeters) => {
        if (!waterMeters) return true;
        return waterMeters?.length > 1;
      }),
    );
  }

  reportProblem(form: {
    waterMeter: string;
    description: string;
    image: string;
  }) {
    return this._http
      .post<ApiResponse<string>>(`${environment.apiUrl}/meters/problem`, {
        meter_id: this._currentWmSubject.getValue()?.meter_id,
        description: form.description,
        image: form.image,
      })
      .pipe(
        tap((response) => {
          if (response.status) {
            this._toastr.success(this._translate.transform('notifications.success.problem-reported'), this._translate.transform('notifications.success.title'));
          }
        }),
        catchError((e) => {
          this._toastr.error(e.error.message || this._translate.transform('notifications.error.problem-was-not-reported'), this._translate.transform('notifications.error.title'));
          return throwError(() => new Error(e));
        }),
      );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
/**
 * If the BE is not working properly, you can use this mocked response
 */
// getWaterMeters(): Observable<ApiResponse<IWaterMeter[]>> {
//   // Mocked response object
//   const mockedResponse: ApiResponse<IWaterMeter[]> = {
//       "status": true,
//       "data": [
//         {
//           "meter_id": 1030,
//           "device": "101200791901",
//           "device_sn": "1012-007-91901",
//           "site_id": "",
//           "billing_number": "45775",
//           "monitoring": 1,
//           "source": "gateway",
//           "current": true
//         },
//         {
//           "meter_id": 1030,
//           "device": "10120079190",
//           "device_sn": "1012-007-9190",
//           "site_id": "",
//           "alias": "null",
//           "avg_per_unit": 10.81,
//           "billing_number": "45775",
//           "frequency": "monthly",
//           "last_log": {
//             "alarms":[
//               {
//                 "id": 1,
//                 "name": "BrokenPipe" as never
//               },
//               {
//                 "id": 2,
//                 "name": "back_flow" as never
//               }
//             ],
//             "id": 79977023,
//             "counter": 952092,
//             "readout_time": "2024-01-03 12:05:54",
//             "indicator_backflow": 123123,
//             "indicator_battery_left": 123123,
//             "indicator_flow_rate": 123123,
//             "indicator_water_pressure": 123123,
//             "indicator_water_temp": 123123,
//           },
//           "monitoring": 1,
//           "source": "gateway",
//           "current": true
//         }
//       ]
//
//   };
//
//   // Immediately return the mocked response as an Observable
//   return of(mockedResponse).pipe(
//     tap((response) => {
//       if ('data' in response && response.data.length > 0) {
//         this.wmsSubject.next(this.initedWaterMeters(response.data));
//         this.setCurrentWaterMeter(response.data[0].device_sn);
//       } else {
//         this.wmsSubject.next(null);
//       }
//       this.isLoading = false;
//     }),
//     catchError((e: HttpErrorResponse) => {
//       console.log('Error:', e);
//       this.toastr.error(e.message || 'An error occurred', 'Error');
//       this.isLoading = false;
//       return throwError(() => new Error(e.message || 'Unknown error'));
//     })
//   );
// }
