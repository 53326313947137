import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

// flowbite

// services
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import { initAccordions, initDropdowns } from 'flowbite';

// components
import { HeaderComponent } from './components/main/header/header.component';
import { SidenavComponent } from './components/main/sidenav/sidenav.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'aup-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule, RouterOutlet, HeaderComponent, SidenavComponent],
  providers: [TranslateService, TranslatePipe],
})
export class AppComponent implements OnInit {
  title = 'aura-user-portal';

  constructor(
    private _translate: TranslateService,
    private _router: Router,
  ) {
    const locale = navigator.language || 'en-US';
    this._translate.setDefaultLang('en');
    if (locale.includes('ru')) {
      this._translate.use('sl');
    } else {
      this._translate.use('en');
    }
    console.log(environment.apiUrl);
  }

  ngOnInit(): void {
    this._router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        // initFlowbite();
        initAccordions();
        initDropdowns();
      }
    });
  }
}
