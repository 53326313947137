import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import {
  IAnnouncement,
  IAnnouncementResponse,
  IFaq,
  IFaqResponse,
} from '../models/IAnnouncemments.types';

@Injectable({
  providedIn: 'root',
})
export class AnnouncmentsService {
  isLoading: boolean = true;
  private _announcements: BehaviorSubject<Array<IAnnouncement> | null> =
    new BehaviorSubject<Array<IAnnouncement> | null>(null);
  private _faqs: BehaviorSubject<Array<IFaq> | null> =
    new BehaviorSubject<Array<IFaq> | null>(null);

  constructor(
    private _http: HttpClient,
    private _toastr: ToastrService,
  ) {
    this.getAnnouncements();
    this.getFaq();
  }

  get announcements$() {
    return this._announcements.asObservable();
  }

  get faqs$() {
    return this._faqs.asObservable();
  }

  getAnnouncements() {
    this.isLoading = true;
    this._http
      .get<IAnnouncementResponse>(`${environment.apiUrl}/company/announcements`)
      .subscribe({
        next: (res: IAnnouncementResponse) => {
          this._announcements.next(res.announcements);
          this.isLoading = false;
        },
        error: (err) => {
          this._toastr.error(err.error.message || 'An error occurred', 'Error');
          this.isLoading = false;
          console.error(err);
        },
      });
  }

  getFaq() {
    this.isLoading = true;

    this._http
      .get<IFaqResponse>(`${environment.apiUrl}/company/faq`)
      .subscribe({
        next: (res: IFaqResponse) => {
          this._faqs.next(res.faqs);
          this.isLoading = false;
        },
        error: (err) => {
          this._toastr.error(err.error.message || 'An error occurred', 'Error');
          this.isLoading = false;
          console.error(err);
        },
      });
  }
}
