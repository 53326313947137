@if (ancService.isLoading) {
  <aup-loader [isDark]="true"></aup-loader>
} @else {
  <div class="grid grid-cols-5 gap-4">
    <div class="col-span-5 2xl:col-span-3">
      <div class="mb-2 text-2xl font-bold title">{{ 'announcements.announcements' | translate }}</div>
      @if ((ancService.announcements$ | async); as announcements) {
        @if (announcements.length > 0) {
          @for (anc of announcements; track anc.id) {
            <div class="my-4 py-8 base-bg rounded-plate">
              <div class="flex content-center items-center">
                <div class="pr-3 date">{{anc.updated_at | date: 'dd.MM.yyyy'}}</div>
                <div class="pr-2 text-lg font-bold text-black title">{{anc.title}}</div>
                <div class="italic type text-brand-red">{{ (anc.interruption === 1 ? 'announcements.interruption' : '') | translate}}</div>
              </div>
              <div class="py-4">
                <p>{{anc.content}}</p>
              </div>
            </div>
          }
        } @else {
          <div class="flex items-start justify-start">
            <span class="text-lg text-gray-500">{{ 'announcements.no_announcements' | translate }}</span>
          </div>
        }
      }
    </div>
  </div>
}
