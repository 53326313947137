import { Routes } from '@angular/router';

// pages
import { AccountComponent } from '../pages/account/account.component';
import { AnnouncementsComponent } from '../pages/announcements/announcements.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { FaqComponent } from '../pages/faq/faq.component';
import { MeterInfoComponent } from '../pages/meter-info/meter-info.component';
import { ProblemReportingComponent } from '../pages/problem-reporting/problem-reporting.component';

export const mainRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [] },
  { path: 'meter-info', component: MeterInfoComponent, canActivate: [] },
  { path: 'meter-info/:id', component: MeterInfoComponent, canActivate: [] },
  {
    path: 'problem-reporting',
    component: ProblemReportingComponent,
    canActivate: [],
  },
  { path: 'announcements', component: AnnouncementsComponent, canActivate: [] },
  { path: 'faq', component: FaqComponent, canActivate: [] },
  { path: 'account', component: AccountComponent, canActivate: [] },
];
